import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/PartnerHero/PartnerHero';
import Partners from '../components/Partners-details/Partners-details';
import NewsAndAnnouncment from '../components/News/News';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

class News extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Hero />
                <NewsAndAnnouncment />
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default News;