import React, { Component } from 'react';

const initData = {
  "preHeading": "TEAM"
}
const datainfo = [
  {
    "id": 1,
    "title": "GEMS Founding Member",
    "img": "/imgc/founder/Yuen.webp",
    "content": "Yuen Wong",
    "p": "CEO, LABS Group"
  },
  {
    "id": 2,
    "title": "GEMS Founding Member",
    "img": "/imgc/founder/Andy.webp",
    "content": "Andy Koh",
    "p": "CEO, Game One Inc"
  },
  //   {
  //     "id": 3,
  //     "title": "GEMS Founding Member",
  //     "img": "/imgc/founder/Tracy.webp",
  //     "content": "Tracy Sheriden Tan",
  //     "p": "CEO, Arena Esports Hotel",
  //   },
  {
    "id": 4,
    "title": "GEMS Founding Member",
    "img": "/imgc/founder/Angel.webp",
    "content": "Tra My Angel",
    "p": "Famous Esports KOL",
  },
]
const team = [
  {
    "id": 4,
    "img": "/imgc/founder/mike.webp",
    "content": "Mike Pang",
    "p": "Head of Product",
  },
]

class FoundingMember extends Component {
  state = {
    initData: {},
    datainfo: [],
    team: []
  }
  componentDidMount() {
    this.setState({
      initData: initData,
      datainfo: datainfo,
      team: team
    })
  }
  render() {
    return (
      <section className="work-area fbg pTB100">
        <div className="row">
          <div className="col-12">
            {/* Intro */}
            <div className="text-center">
              <span className="wTitle mt50">{this.state.initData.preHeading}</span>
            </div>
          </div>
        </div>
        <div className="row items">

          {this.state.datainfo.map((item, idx) => {

            return (
              <div className="col-12 col-sm-6 col-lg-4 item text-center pfm">
                <img src={item.img} className="founders" />

                {/* Single Work */}
                <div className="single-work tWhite mt20">
                  <p className='text-center m0'>{item.title}</p>
                  <h2 className="Mname m0">{item.content}</h2>
                  <p className='m0'>{item.p}</p>
                </div>
              </div>

            );
          })}
        </div>
        <div className="row justify-content-between mt50">
          {this.state.team.map((item, idx) => {
            return (
              <div className="col-12 col-sm-6 col-lg-3 item text-center pfm">
                <img src={item.img} className="founders" />

                {/* Single Work */}
                <div className="single-work tWhite mt20">
                  <h2 className="Mname m0">{item.content}</h2>
                  <p className="m0">{item.p}</p>
                </div>
              </div>

            );
          })}
        </div>
      </section>
    );
  }
}

export default FoundingMember;