import React, { Component } from 'react';

const initData = {
    itemImg: "/imgc/launcher.webp",
    title: "GEMS IS",
    title2: "AN ESPORTS 3.0 AGGREGATOR",
    title3: "PLATFORM WITHIN AN O2O ECOSYSTEM",
    p: "GEMS propels Esports into 3.0 by integrating Gamefi, Metaverse and Socialfi elements, and leverages on its founding partners' chain of Esports hotels."
}


class ItemDetails extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="item-details-area pTB100">
                <div>
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-5">
                            <div className="item-info">
                                <div className="item-thumb text-center">
                                    <img src={this.state.initData.itemImg} alt="GEMS" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 fbox">
                            {/* Content */}
                            <div className="intro text-center">
                                <h1 className="ptc ctm2nd"> {this.state.initData.title}</h1>
                                <h2>{this.state.initData.title2} <br />{this.state.initData.title3}</h2>
                                <p className="p40">{this.state.initData.p}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        );
    }
}

export default ItemDetails;