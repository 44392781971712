import React, { Component } from "react";

const initData = {
  img: "/imgc/logo.webp",
  content:
    "GEMS’ one-of-a-kind ecosystem is designed to be the ultimate, one-stop Gaming destination, connecting crypto and esports gamers.",
  widget_1: "LINKS",
  widget_2: "LEARN MORE",
  widget_3: "SUBSCRIBE US"
}
const socialData = [
  {
    id: "1",
    icon: "fa-brands fa-discord",
    link: "https://discord.com/invite/gemsggofficial",
  },
  {
    id: "2",
    icon: "fa-brands fa-twitter",
    link: "https://twitter.com/gemsgg_official",
  },
  {
    id: "3",
    icon: "fa-brands fa-telegram",
    link: "https://t.me/thegemsgg",
  },
  {
    id: "4",
    icon: "fa-brands fa-medium",
    link: "https://thegemsgg.medium.com/",
  },
  {
    id: "5",
    icon: "fa-brands fa-facebook",
    link: "https://www.facebook.com/GEMS-101694072369634",
  },
  {
    id: "6",
    icon: "fa-brands fa-instagram",
    link: "https://www.instagram.com/gemsggofficial/",
  }
]
const widgetData_1 = [
  {
    id: "1",
    text: "Home",
    link: "/",
  },
  {
    id: "2",
    text: "About Us",
    link: "/about",
  },
  {
    id: "3",
    text: "News & Announcement",
    link: "/news",
  },
  {
    id: "3",
    text: "FAQ",
    link: "/faq",
  }
]

const widgetData_2 = [
  {
    id: "1",
    text: "Pitch Deck",
    link: "https://drive.google.com/drive/u/1/folders/1nBYZ5P9Trm88v2Bo7mSmsMXwY2QXlowH",
  },
  {
    id: "2",
    text: "Tokenomics",
    link: "https://drive.google.com/drive/u/1/folders/1B-UT2HyxIUPzYkEttoxO8QkPZq4LAE45",
  },
  {
    id: "3",
    text: "Gitbook",
    link: "https://official-gems.gitbook.io/gemsofficial/",
  }
]

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initData: {},
      socialData: [],
      widgetData_1: [],
      widgetData_2: [],
      show: true,
      isShowSocialButtons: true
    }
    this.onToggleSocialButtons = this.onToggleSocialButtons.bind(this);
  }
  componentDidMount() {
    this.setState({
      initData: initData,
      socialData: socialData,
      widgetData_1: widgetData_1,
      widgetData_2: widgetData_2,
      show: false,
      isShowSocialButtons: true
    })
  }
  onToggleSocialButtons(flag) {
    this.setState({ isShowSocialButtons: !flag })
  }
  render() {
    return (
      <>
        <footer className="footer-area pLR20">
          {/* Footer Top */}
          <div className="footer-top">
            {/* <div className="container"> */}
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 res-margin">
                {/* Footer Items */}
                <div className="footer-items brandS">
                  {/* Logo */}
                  <a className="navbar-brand" href="/">
                    <img src={this.state.initData.img} alt="GEMS" width="80%" />
                  </a>
                  <p>{this.state.initData.content}</p>
                  {/* Social Icons */}
                  <div className="social-icons d-flex justify-content-center">
                    {this.state.socialData.map((item, idx) => {
                      return (
                        <a key={`sd_${idx}`} href={item.link} target="_blank">
                          <i className={item.icon} />

                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 res-margin mt30">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h4 className="footer-title">{this.state.initData.widget_1}</h4>
                  <ul>
                    {this.state.widgetData_1.map((item, idx) => {
                      return (
                        <li key={`wdo_${idx}`}>
                          <a href={item.link}>{item.text}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 res-margin mt30">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h4 className="footer-title">{this.state.initData.widget_2}</h4>
                  <ul>
                    {this.state.widgetData_2.map((item, idx) => {
                      return (
                        <li key={`wdo_${idx}`}>
                          <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                {/* Footer Items */}
                <div className="footer-items pt30">
                  {/* Footer Title */}
                  <h4 className="footer-title">{this.state.initData.widget_3}</h4>
                  {/* Subscribe Form */}
                  <div className="subscribe-form d-flex align-items-center">
                    <form id="subscription" className="item-form no-hover" method="POST" action="assets/php/subcribe.php">

                      <input
                        type="email"
                        id="mce-EMAIL"
                        name="email"
                        className="form-control"
                        placeholder="Your email here"
                      />
                      <button type="submit" className="btn" name="subscribe" id="mc-embedded-subscribe">
                        <i className="icon-paper-plane" />
                      </button>
                    </form>
                  </div>
                  <p className="mb-0 contact-us-footer">Contact us: <a href="mailto:contact@thegems.gg">contact@thegems.gg</a></p>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            {/* <div className="container"> */}
            <div className="row">
              <div className="col-12">
                {/* Copyright Area */}
                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                  {/* Copyright Left */}
                  <div className="copyright-left">
                    ©2022 GEMS, All Rights Reserved. Read our <a href="/privacypolicy">Privacy Policy</a> and <a href="/terms">Terms of Use</a>
                  </div>
                  {/* Copyright Right */}
                  <div className="copyright-right">
                    GEMS - ESPORTS 3.0 PLATFORM
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </footer>
        {this.state.isShowSocialButtons &&
          <div className="social-fixed">
            <div className="social-arrow-open">
            <div className="social-icons">
                <div onClick={() => this.onToggleSocialButtons(this.state.isShowSocialButtons)}>
                  <div className="arrow-right"> &lt; </div>
                </div>
            </div>
          </div>
            <div className="social-icons">
              {this.state.socialData.map((item, idx) => {
                return (
                  <a key={`sd_${idx}`} href={item.link} target="_blank">
                    <i className={item.icon} />

                  </a>
                );
              })}
            </div>
          </div>
        }
        {!this.state.isShowSocialButtons &&
          <div className="social-arrow-close">
            <div className="social-icons">
                <div onClick={() => this.onToggleSocialButtons(this.state.isShowSocialButtons)}>
                  <div className="arrow-right"> &gt; </div>
                </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default Footer;
