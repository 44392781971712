import React, { Component } from 'react';

const initData = {
    "Heading": "WE ARE O2O",
    "subHeading": "ONLINE TO OFFLINE",
    "p": "GEMS is here to provide a 360-degree, fully immersive gaming experience. With an ambitious goal to own 50 hotel touchpoints in 3 years, players can gather teams and battle in competitions in GEMS’ unique ecosystem and blends the best of both the Esports and GameFi world. "
}

const datainfo = [
    {
      "id": 1,
      "imgc": "/imgc/arena/1.webp",
    },
    {
      "id": 2,
      "imgc": "/imgc/arena/2.webp",
    },
    {
      "id": 3,
      "imgc": "/imgc/arena/3.webp",
    },
    {
      "id": 4,
      "imgc": "/imgc/arena/4.webp",
    },
    {
      "id": 5,
      "imgc": "/imgc/arena/5.webp",
    },
    {
      "id": 6,
      "imgc": "/imgc/arena/6.webp",
    },
    {
      "id": 7,
      "imgc": "/imgc/arena/7.webp",
    },
    {
      "id": 8,
      "imgc": "/imgc/arena/8.webp",
    },
]

class Arena extends Component {
    state = {
        initData:{},
        datainfo: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            datainfo: datainfo
        })
    }
    render() {
        return (
            <section className="explore-area p0">
                    
                    <div className="row explore-slides fbox darkPurple">
                        <div className="col-12 col-sm-6 col-lg-6 p100">
                          <div className="intro text-center">
                                  
                                  <div>
                                      <span>{this.state.initData.Heading}</span>
                                      <h2>{this.state.initData.subHeading}</h2>
                                      <p className='tWhite'>{this.state.initData.p}</p>
                                  </div>
                            
                          </div>
                        </div>
                        <div className="swiper-container full-slider col-12 col-sm-6 col-lg-6 m0 p0">
                            <div className="swiper-wrapper">
                                {/* Single Slide */}
                                {this.state.datainfo.map((item, idx) => {
                                    return (
                                        <div key={`exfi_${idx}`} className="swiper-slide item">
                                            <div>
                                                <div className="image-over">
                                                        <img src={item.imgc} alt="Arena Esports Hotel" />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {/* <div className="swiper-pagination" /> */}
                        </div>
                </div>
            </section>
        );
    }
}

export default Arena;