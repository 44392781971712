import React, { Component } from 'react';

const initData = {
    "preHeading": "GEMS Ecosystem",
    "Heading": "INTRODUCING ESPORTS 3.0",
    "subHeading": "The ultimate goal of GEMS is to create a safe haven for Gamers to compete, explore and earn while playing games with a lower pricepoint. The ecosystem consists of:"
}
const datainfo = [
      {
        "id": 1,
        "img": "/imgc/product_nft-rental-portal.webp",
      },
      {
        "id": 2,
        "img": "/imgc/product_defi-union.webp",
      },
      {
        "id": 3,
        "img": "/imgc/product_fanfi-studio.webp",
      },
      {
        "id": 4,
        "img": "/imgc/product_dao-guild.webp",
      },
      {
        "id": 5,
        "img": "/imgc/product_p2e.webp",
      },
]

class ProductRow extends Component {
    state = {
      initData: {},
      datainfo: []
  }
  componentDidMount(){
      this.setState({
          initData: initData,
          datainfo: datainfo
      })
  }
    render() {
        return (
            <section className="work-area pbg pTB100">
                    <div className="row fbox">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <img src="/imgc/launcher.webp" alt="launcher" className="mAuto2" />
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            {/* Intro */}
                            <div className="text-center">
                                
                                    <span className='wTitle'>{this.state.initData.preHeading}</span>
                                    <h2>{this.state.initData.Heading}</h2>
                                    <p className="pLR200 tWhite">{this.state.initData.subHeading}</p>
                               
                            </div>
                            <div className="row items">
                                <div className="col-0 col-sm-1 col-lg-1 item text-center"></div>
                                {this.state.datainfo.map((item, idx) => {
                                    return (
                                    <div className="col-6 col-sm-2 col-lg-2 item text-center">
                                        <img src={item.img} />
                                    </div>

                                    );
                                })}
                                
                                <div className="col-0 col-sm-1 col-lg-1 item text-center"></div>
                            </div>
                            <div className='mt50 text-center'>
                                <ul className="navbar-nav action">
                                    <li className="nav-item ml-3">
                                        <a href="/about" className="btn ml-lg-auto btn-bordered-white">Learn More</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
            </section>
        );
    }
}

export default ProductRow;