import React, { Component } from 'react';



const data = [
    {
        id: "1",
        imgH: "/imgc/gamefi-h.webp",
        imgV: "/imgc/gamefi-v.webp",
        altxt: "GameFi"
    },
    {
        id: "2",
        imgH: "/imgc/esport-h.webp",
        imgV: "/imgc/esport-v.webp",
        altxt: "Esport"
    },
    {
        id: "3",
        imgH: "/imgc/metaverse-h.webp",
        imgV: "/imgc/metaverse-v.webp",
        altxt: "Metaverse"
    },
    {
        id: "4",
        imgH: "/imgc/socialfi-h.webp",
        imgV: "/imgc/socialfi-v.webp",
        altxt: "SocialFi"
    }
]

class ExploreFive extends Component {
    state = {
       
        data: []
    }
    componentDidMount(){
        this.setState({
            
            data: data
        })
    }
    render() {
        return (
            <section className="explore-area p0 pb65">
                <div className="container">
                    
                    <div className="explore-slides">
                        <div className="swiper-container full-slider items">
                            <div className="swiper-wrapper">
                                {/* Single Slide */}
                                {this.state.data.map((item, idx) => {
                                    return (
                                        <div key={`exfi_${idx}`} className="swiper-slide item">
                                            <div>
                                                <div className="image-over">
                                                    
                                                        <img src={item.imgH} alt={item.altxt}  className="imgH" />
                                                        <img src={item.imgV} alt={item.altxt}  className="imgV" />
                                                    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {/* <div className="swiper-pagination" /> */}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ExploreFive;