import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
// import Auctions from '../components/Auctions/AuctionsOne';
// import TopSeller from '../components/TopSeller/TopSellerOne';
// import Collections from '../components/Collections/Collections';
// import Explore from '../components/Explore/ExploreOne';
import Row2nd from '../components/Row2nd/Row2nd';
// import Row5th from '../components/Row5th/Row5th';
import ProductRow from '../components/ProductRow/ProductRow';
import Roadmap from '../components/Roadmap/Roadmap';
import InvestBy from '../components/InvestBy/InvestBy';
import Partners from '../components/Partners/Partners';
// import TextBanner from '../components/TextBanner/TextBanner';
import FoundingMember from '../components/FoundingMember/FoundingMember';
import Adviser from '../components/Adviser/Adviser';
import Whitepaper from '../components/Whitepaper/Whitepaper';
import News from '../components/News/News';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

class index extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Hero />
                <Row2nd />
                {/* <Row5th /> */}
                <ProductRow />
                {/* <TextBanner /> */}
                <InvestBy ishome="true" />
                <Partners />
                <Roadmap />
                <Whitepaper />
                <FoundingMember />
                <Adviser />
                <News />
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default index;