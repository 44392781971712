import React, { Component } from "react";

const initData = {
    "preHeading": "F.A.Q.",
    "faqQuestionIcon": "/imgc/logo-icon-white.svg"
}
const faqInfo = [
    {
      "id": 1,
      "title": "1. What is GEMS?",
      "info": "GEMS stands for GameFi, Esports, Metaverse and SocialFi Proudly presenting Esports 3.0, GEMS is an Esports 3.0 Aggregator Platform within a unique O2O (online-to-offline) Ecosystem. Our one-of-a-kind ecosystem is designed to be the ultimate, one-stop GameFi destination, allowing crypto and Esports gamers to learn, connect, and immerse themselves in different metaverses and GameFi worlds via online and offline touch points.",
    },
    {
      "id": 2,
      "title": "2. What is E-GEMS?",
      "info": "E-GEMS is not a listed token on any CEX/DEX. This is purely an in-game currency and it can only be purchased by GEMS.",
    },
    {
      "id": 3,
      "title": "3. What is Esports 3.0?",
      "info": "Esports 3.0 is building a #Web3 decentralized platform specifically designed to bring Esports into the blockchain by integrating mainly smart contracts among the key stakeholders in the Esports industry.",
    },
    {
      "id": 4,
      "title": "4. What is the NFT Marketplace?",
      "info": "GEMS Marketplace provides a new way to experience and earn from GameFi, Esports, Metaverse, and SocialFi projects. It allows blockchain gamers to access a wide variety of powerful and rare NFTs to power up at a fraction of its price point by providing multiple payment options - Rental, Buy It Now, Auctions and Installments.",
    },
    {
      "id": 5,
      "title": "5. What is GEMS’s token utility?",
      "info": "Our platform provides a marketplace for gamers to buy, sell and rent NFT. Gamers are also welcome to stake GEMS to earn APY.",
    },
    {
      "id": 6,
      "title": "6. How to stake GEMS?",
      "info": "GEMS Esports 3.0 Platform launches IDO on the below launchpads! PolkaBridge" + '\n\n' +
      "<strong>PolkaBridge</strong>" + '\n' +
      "<strong>@polkabridgegroup</strong> is a decentralized all-in-one financial application platform, include AMM, Launchpad, INO, Join IDO by staking $PBR/$LABS to get your Guaranteed $GEMS at" + " " + "<a href='https://launchpad.polkabridge.org/#/launchpads/view/GEMS-PBR/50'>https://launchpad.polkabridge.org/#/launchpads/view/GEMS-PBR/50</a>" + '\n' +
      "Step 1. Go to PolkaBridge Launch Pad (connect to the BSC network in order to view the content)" + '\n' +
      "Step 2. Fill in the IDO registration form (*must complete the form)" + '\n' +
      "Step 3. Go to Stake Pools and connect your wallet to Ethereum. Stake at least 200,000$LABS / 10,000$PBR until IDO ends. *You will not get any GEMS allocations if you do not stake till IDO ends." + '\n\n' +
      "<strong>FORMLESS</strong" + '\n' +
      "<strong>@formless_official</strong> The world's leading $P2E GameFi aggregator linking games, gamers and guilds. Complete these gleam tasks to get $100 whitelist quota:" + " " +  "<a href='http://bit.ly/3kwEfra'>http://bit.ly/3kwEfra</a>" + " and" + "<a href='https://www.formless.me/IEO '>https://www.formless.me/IEO </a>" + '\n\n' +
      "<strong>BLOKPAD</strong>" + '\n' + 
      "<strong>@OfficialBLOKPADAnn</strong> is the first Launchpad in the Metaverse." + '\n' + 
      "Step 1. Go to " + "<a href='https://essential.bloklaunchpad.com/levels'>https://essential.bloklaunchpad.com/levels</a>" + '\n' +
      "Step 2. Connect your wallet" + '\n' +
      "Step 3. Stake enough $BLOK to get one of the levels. You can see the required amount to stake in the list of levels" + '\n\n' +
      "<strong>DuckSTARTER</strong>" + '\n' +
      "<strong>@duckdaolobby</strong> is the world’s largest cross chain Incubator for crypto projects. Go to " + "<a href='https://duckstarter.io'>https://duckstarter.io</a>" + " and check it out.",
    },
    {
        "id": 7,
        "title": "7. How can GEMS benefit the traditional Esports player?",
        "info": "By incorporating traditional PC and Esports tournaments, GEMS will become a holistic online hyper gaming market store, as it has developed a scheme that integrates key gaming digital services. Everyone can benefit from having all the different components of GEMS which are accessible in one platform." + '\n\n' + "GEMS' O2O integration to the offline world through developments and partnerships with actual hotels makes it easier for traditional Esports players to experience the magic of Esports, providing all necessary tools such as the DAO Guild for beginners to get familiar with Esports effortlessly.",
    },
    {
        "id": 8,
        "title": "8. What gives GEMS a competitive edge over other competitors?",
        "info": "The major differences between GEMS and its competitors is the uniqueness of the platform." + '\n' +
        "1. The first Esports project on blockchain" + '\n' +
        "2. The first platform to connect online Esports games to offline touchpoints" + '\n' +
        "3. The NFT rental portal, there has never been any project where one could rent NFTs for a fraction of its original price" + '\n' +
        "4. The first platform to ever integrate all other Gamefi projects" + '\n\n' +
        "Our platform empowers gamers to maximize their in-game profit with tips from professional gamers and powerful NFT add-ons powered by GEMS DAO Guild community.",
    },
    {
        "id": 9,
        "title": "9. What are the upcoming plans of the project?",
        "info": "GEMS plans to look into and execute the following from the third quarter of 2022 to 2023." + '\n\n' +
        "<strong>Q3/22</strong>" + '\n' +
        "<ul>" +
        "<li>NFT Marketplace | Phase 1 Production</li>" +
        "<li>GEMS Community Platform | Phase 1 - Production</li>" +
        "<li>P2E Arena | Phase 1 - Production</li>" +
        "<li>GEMS Fantasy Leagues | Phase 1 (Predictions) - Production</li>" +
        "<li>GEMS Minicade | Phase 1 - Quick fire Arcade style Games to win GEMS</li>" +
        "<li>GEMS Gamefi Review Site -  Latest Gamefi Reviews and countdowns</li>" +
        "</ul>" + '\n\n' +
        "<strong>Q4/22</strong>" + '\n' +
        "<ul>" +
        "<li>P2E Arena | Phase 2 - Enhancement</li>" +
        "<li>FanFi Studio | Phase 1 - Production</li>" +
        "<li>GameFi Review | Phase 1 - Production</li>" +
        "<li>DAO Guild Platform Production | Phase 1 - Production</li>" +
        "<li>DEFI Union - Staking Pools</li>" +
        "<li>NFT Bridge powered by Arcade Network</li>" +
        "</ul>" + '\n\n' +
        "<strong>Q1/23</strong>" + '\n' +
        "<ul>" +
        "<li>FanFi Studio | Phase 2 - Enhancement</li>" +
        "<li>FanFi Academy | Phase 1 - Production</li>" +
        "<li>FanFi Talent Agency | Phase 1 - Production</li>" +
        "<li>Blockchain Gaming Tournaments</li>" +
        "</ul>" + '\n\n' +
        "<strong>Q2/23</strong>" + '\n' +
        "<ul>" +
        "<li>DeFi Union | Phase 2 - Enhancement</li>" +
        "</ul>"
    },
    {
        "id": 10,
        "title": "10. How to make money from GEMS Esports 3.0 Platform?",
        "info": "There are many GEMS monetization opportunities." + '\n' +
        "1. GameFi P2E - Earn money by playing games" + '\n' +
        "2. SocialFi - Fans, streamers and influencers can earn money by participating in FanFi Studio & DAO Guild. Fans can earn fan tokens and streamers can make money with live streaming. GEMS will help these talents reach the right audiences" + '\n' +
        "3. The NFT rental portal, there has never been any project where one could rent NFTs for a fraction of its original price" + '\n' +
        "3. Prediction - Guess the tournament winner, players can loan for leverage capital for match result prediction" + '\n' +
        "4. Win the tournament",
    },
]

class FaqContent extends Component {
    state = {
        initData: {},
        faqInfo: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            faqInfo: faqInfo,
        })
    }
    render() {
        return(
            <section className="section-faq-content pTB100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="text-center">
                                <span className="wTitle mt50">{this.state.initData.preHeading}</span>
                            </div>
                        </div>
                    </div>
                    <div className="mt50">
                        {this.state.faqInfo.map((item, idx) => {
                            
                            return (
                                <div className="accordion-item">
                                    <button class="btn btn-primary accordion-button collapsed" data-toggle="collapse" href={"#collapse" + item.id} role="button" aria-expanded="false" aria-controls={"collapse" + item.id}>
                                        <div className="icon-wrapper">
                                            <img src={initData.faqQuestionIcon} className="icon-img" />
                                        </div>
                                        <div>
                                            {item.title}
                                        </div>
                                    </button>
                                    <div class="accordion-body">
                                        <div class="collapse multi-collapse" id={"collapse" + item.id}>
                                            <div class="text">
                                                <div dangerouslySetInnerHTML={{__html: item.info}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt40">
                        <img src="/imgc/economy_and_monitization.png" />
                    </div>
                </div>
            </section>
        );
    }
}

export default FaqContent;