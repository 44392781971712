import React, { Component } from 'react';


class Hero extends Component {
    
    render() {
        return (
            <section className="hero embed-container">
            <div className='D'>
                <iframe src="https://player.vimeo.com/video/706041475?h=09544e2826&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;background=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen className='vv' title="GEMS-Teaser"></iframe>
            </div>
            <div className='M vcontainer'>
                <iframe src="https://player.vimeo.com/video/706041445?h=09544e2826&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;background=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen className='vv' title="GEMS-Teaser_mobile"></iframe>
            </div>
            </section>
        );
    }
}

export default Hero;