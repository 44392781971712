import React, { Component } from 'react';

import Header from '../components/Header/Header';
import AboutHero from '../components/AboutHero/AboutHero';
import AboutRow1st from '../components/AboutRow1st/AboutRow1st';
import AboutRow2nd from '../components/AboutRow2nd/AboutRow2nd';
import AboutRow3rd from '../components/AboutRow3rd/AboutRow3rd';
import Arena from '../components/Arena/Arena';
import Row5th from '../components/Row5th/Row5th';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

class About extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <AboutHero />
                <AboutRow1st />
                <AboutRow2nd />
                <AboutRow3rd />
                <Row5th />
                <Arena />
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default About;