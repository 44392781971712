import React, { Component } from 'react';

const initData = {
    "preHeading": "Founding Partners"
}
const datainfo = [
    {
        "id": 1,
        "title": "LABS GROUP",
        "img": "/imgc/founder/LABS.webp",
        "content": "LABS Group covers Real Estate Digitization. LABS is a digital investment platform that provides access to fractionalized property and business ownership. ",
        "link": "https://labsgroup.io/",
    },
    //   {
    //     "id": 2,
    //     "title": "ARENA ESPORTS HOTEL",
    //     "img": "/imgc/founder/Arena.webp",
    //     "content": "Arena Esports Hotel is a leading global franchise for Esports hotels, hostels, resorts, and serviced residences. Embracing the global Esports trend and being one of the first to combine the gaming arena and hospitality in Southeast Asia, they are on a mission to promote Esports. ",
    //     "link": "https://www.arenaesportshotel.com/",
    //   },
    {
        "id": 3,
        "title": "GAME ONE INC",
        "img": "/imgc/founder/GameOne.webp",
        "content": "Game One Inc is our resident gaming master. With nearly 20 years of experience in delivering top-of-the-line solutions in the Esports industry, they have been providing specialized products and services revolving around the Esports ecosystem, including management and launching major esports events in SEA. ",
        "link": "https://gameone.com.vn/",
    },
]

class Founder extends Component {
    state = {
        initData: {},
        datainfo: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            datainfo: datainfo
        })
    }
    render() {
        return (
            <section className="work-area pTB100">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro text-center">
                            <div className="intro-content">
                                <span>{this.state.initData.preHeading}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row items">
                    {this.state.datainfo.map((item, idx) => {
                        return (
                            <div className="col-12 col-sm-4 col-lg-4 item text-center">
                                <a href={item.link} target="_blank"><img src={item.img} /></a>
                                <h4 className='text-center'>{item.title}</h4>
                                {/* Single Work */}
                                <div className="single-work p20">
                                    {item.content}
                                </div>
                            </div>

                        );
                    })}
                </div>
            </section>
        );
    }
}

export default Founder;