import React from 'react';
import { useEffect, useState } from "react";
import CountDownTimer from "../DateTimeCounter/CountDownTimer";

const Header = () => {
    const dateTimeIDO = new Date("2022-08-18T13:00:00").getTime();
    const [targetDate, setTargetDate] = useState(
        new Date(dateTimeIDO)
    );
    return (
        <header id="header" className="header-site iscountdown">
            <div className="top-bar">
                {
                /* <div className="countdown-timer-container">
                    <a href="https://logwork.com/countdown-w7sm" class="countdown-timer" data-timezone="Asia/Beirut" data-textcolor="#ffffff" data-unitscolor="#ffffff" data-date="2022-08-25 00:00">Countdown</a>
                </div> */
                /* <div className="mess-header-fixed">
                    <div className="countdown"></div>
                    <h4>TGE Date: 18 Aug 1pm UTC</h4>
                    <CountDownTimer targetDate={targetDate} />

                    TODO: update launch 3/8
                    <div className="clr-yellow font-semibold">
                        Address: xxxxxx<br />
                        Get it from: <a href="https://www.bitget.com/en/rewards/register?from=referral&clacCode=J3APKR31" tagert="_blank">Bitget</a>, <a href="https://www.gate.io/" tagert="_blank">Gate.io</a>, <a href="https://www.bitmart.com/" tagert="_blank">Bitmart</a>
                    </div>

                </div> */}
            </div>
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                <div className="heading">
                    <div className="navbar-main d-flex justify-content-between align-items-center">
                        {/* Navbar Brand*/}
                        <div className='logoS'>
                            <a className="navbar-brand" href="/">
                                <img className="navbar-brand-sticky" src="imgc/logo.webp" alt="sticky brand-logo" />
                            </a>
                        </div>
                        <div className="menuS">
                            {/* Navbar */}
                            <ul className="navbar-nav items mx-auto">
                                {/* <li className="nav-item ml-3 mbGL">
                                    <a href="/wallet-connect" className="btn ml-lg-auto btn-bordered-white">Start Playing</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                <li className="d-block d-xl-none nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">About Us</a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                        <li><a className="dropdown-item" href="/about">Our Platform</a></li>
                                        <li><a className="dropdown-item" href="/investors">Investors</a></li>
                                        {/* <li><a class="dropdown-item" href="#">Roadmap</a></li> */}
                                        <li><a className="dropdown-item" href="/partners">Partners</a></li>
                                        <li><a className="dropdown-item" href="/team">Team</a></li>
                                    </ul>
                                </li>
                                <li className="d-none d-xl-inline-block nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/about" role="button" data-bs-toggle="dropdown" aria-expanded="false">About Us</a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                        <li><a className="dropdown-item" href="/about">Our Platform</a></li>
                                        <li><a className="dropdown-item" href="/investors">Investors</a></li>
                                        {/* <li><a class="dropdown-item" href="#">Roadmap</a></li> */}
                                        <li><a className="dropdown-item" href="/partners">Partners</a></li>
                                        <li><a className="dropdown-item" href="/team">Team</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="https://drive.google.com/drive/u/0/folders/1nBYZ5P9Trm88v2Bo7mSmsMXwY2QXlowH" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false" target="_blank">Documents</a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                        <li><a className="dropdown-item" target="_blank" href="https://drive.google.com/drive/u/1/folders/1nBYZ5P9Trm88v2Bo7mSmsMXwY2QXlowH">Pitch Deck</a></li>
                                        <li><a className="dropdown-item" target="_blank" href="https://drive.google.com/drive/u/1/folders/1B-UT2HyxIUPzYkEttoxO8QkPZq4LAE45">Tokenomics</a></li>
                                        <li><a className="dropdown-item" target="_blank" href="https://official-gems.gitbook.io/gemsofficial/">Gitbook</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/contactus">Contact Us</a>
                                </li>
                                {/* <li className="nav-item text-start">
                                    <a className="d-block d-xl-none nav-link text-color-primary" href="#">GEMS.GG</a>
                                    <a className="d-none d-xl-block nav-link btn ml-lg-auto btn-bordered-white" href="#">GEMS.GG</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" href="/news">News & Annoucement</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/faq">FAQ</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="/partners">Partners</a>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="/team">Team</a>
                                </li> */}

                            </ul>
                            {/* Navbar Icons */}

                            {/* Navbar Toggler */}
                            <ul className="navbar-nav toggle">
                                <li className="nav-item">
                                    <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                        <i className="fas fa-bars toggle-icon m-0" />
                                    </a>
                                </li>
                            </ul>
                            {/* Navbar Action Button */}
                            {/* <ul className="navbar-nav action">
                                <li className="nav-item ml-3">
                                    <a href="/wallet-connect" className="btn ml-lg-auto btn-bordered-white">Start Playing</a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;