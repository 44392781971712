import React, { Component } from 'react';

const initData = {
    "preHeading": "Roadmap"
}
const r2022Q3 = [
      {
        "id": 1,
        "title": "NFT Marketplace | Phase 1 Production",
      },
      {
        "id": 2,
        "title": "GEMS Community Platform | Phase 1 - Production",
      },
      {
        "id": 3,
        "title": "P2E Arena | Phase 1 - Production",
      },
      {
        "id": 4,
        "title": "GEMS Fantasy Leagues | Phase 1 (Predictions) - Production",
      },
      {
        "id": 5,
        "title": "GEMS Minicade | Phase 1 - Quick fire Arcade style Games to win GEMS",
      },
      {
        "id": 6,
        "title": "GEMS Gamefi Review Site - Latest Gamefi Reviews and countdowns",
      }
]
const r2022Q4 = [
    {
      "id": 1,
      "title": "P2E Arena | Phase 2 - Enhancement",
    },
    {
      "id": 2,
      "title": "FanFi Studio | Phase 1 - Production",
    },
    {
      "id": 3,
      "title": "GameFi Review | Phase 1 - Production",
    },
    {
        "id": 4,
        "title": "DAO Guild Platform Production | Phase 1 - Production",
    },
    {
        "id": 5,
        "title": "DEFI Union - Staking Pools",
    },
    {
        "id": 6,
        "title": "NFT Bridge powered by Arcade Network",
    }
]
const r2023Q1 = [
    {
      "id": 1,
      "title": "FanFi Studio | Phase 2 - Enhancement",
    },
    {
      "id": 2,
      "title": "FanFi Academy | Phase 1 - Production",
    },
    {
      "id": 3,
      "title": "FanFi Talent Agency | Phase 1 - Production",
    },
    {
        "id": 4,
        "title": "Blockchain Gaming Tournaments",
    }
]
const r2023Q2 = [
    {
      "id": 1,
      "title": "DeFi Union | Phase 2 - Enhancement",
    }
]

class Roadmap extends Component {
    state = {
      initData: {},
      r2022Q3: [],
      r2022Q4: [],
      r2023Q1: [],
      r2023Q2: [],
  }
  componentDidMount(){
      this.setState({
          initData: initData,
          r2022Q3: r2022Q3,
          r2022Q4: r2022Q4,
          r2023Q1: r2023Q1,
          r2023Q2: r2023Q2
      })
  }
    render() {
        return (
            <section className="work-area pb-0">
                <div className="rmbg">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro text-center">
                                <div className="intro-content">
                                    <span>{this.state.initData.preHeading}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                        <div className="col-12 col-sm-12 col-lg-3 item">
                                        {/* Single Work */}
                                        <div className="single-work">
                                        <h2 className='rmh1'>Q3/22</h2>
                                            {this.state.r2022Q3.map((item, idx) => {
                                                return (
                                                            <ul key={`wd_${idx}`}>
                                                                <li>{item.title}</li>
                                                            </ul>
                                                );
                                            })}
                                        </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-3 item">
                                        {/* Single Work */}
                                        <div className="single-work">
                                          <h2 className='rmh1'>Q4/22</h2>
                                            {this.state.r2022Q4.map((item, idx) => {
                                                return (
                                                            <ul key={`wd_${idx}`}>
                                                                <li>{item.title}</li>
                                                            </ul>
                                                );
                                            })}
                                        </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-3 item">
                                        {/* Single Work */}
                                        <div className="single-work">
                                        <h2 className='rmh1'>Q1/23</h2>
                                            {this.state.r2023Q1.map((item, idx) => {
                                                return (
                                                            <ul key={`wd_${idx}`}>
                                                                <li>{item.title}</li>
                                                            </ul>
                                                );
                                            })}
                                        </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-3 item">
                                        {/* Single Work */}
                                        <div className="single-work">
                                        <h2 className='rmh1'>Q2/23</h2>
                                            {this.state.r2023Q2.map((item, idx) => {
                                                return (
                                                            <ul key={`wd_${idx}`}>
                                                                <li>{item.title}</li>
                                                            </ul>
                                                );
                                            })}
                                        </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Roadmap;