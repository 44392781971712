import React, { Component } from 'react';

const initData = {
    "preHeading": "INTRODUCING ESPORTS 3.0",
    "p": "This is the first time players get to own digital assets and have more say in the gaming community, and economy. In the traditional esports / gaming world, gamers will have to pay for weapons and skins that they don’t own. Middleman will take out a big chunk of fees. Now, for the very first time, gamers can own something through the use of blockchain and NFT. Similar to Web 3, people can now read, write, and own the content, and fully immerse themselves in the gaming world. "
}


class AboutRow2nd extends Component {
    state = {
      initData: {}
  }
  componentDidMount(){
      this.setState({
          initData: initData
      })
  }
    render() {
        return (
            <section className="work-area pbg pTB100">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="text-center">
                                
                                    <span className='wTitle'>{this.state.initData.preHeading}</span>
                                    <p className="pLR200 tWhite">{this.state.initData.p}</p>
                               
                            </div>
                        </div>
                    </div>
            </section>
        );
    }
}

export default AboutRow2nd;