import React, { Component } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const initData = {
    pre_heading: "Get In Touch",
   
}

class GetInTouch extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.SendMessage = this.SendMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            formMessage: "",
            recaptchaToken: ""
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    UPLOAD_ENDPOINT = '/assets/php/mail.php';
    // UPLOAD_ENDPOINT = 'http://127.0.0.1/test_contact_mail/mail.php';

    RECAPTCHA_SITE_KEY = "6LdQRa4hAAAAADMkyaWA-5C2CH1gDQrsA81_eAbM";

    handleRecaptcha = (token) => {
        this.setState({
            recaptchaToken: token
        });
    }

    async onSubmit(e){
        e.preventDefault() 
        let res = await this.SendMessage(this.state.name, this.state.email, this.state.message, this.state.recaptchaToken);
        this.setState({
            formMessage: res.data
        });
    }

    async SendMessage(name, email, message, recaptchaToken) {
        const formData = new FormData();      
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('recaptchaToken', recaptchaToken);
             
        return  await axios.post(this.UPLOAD_ENDPOINT, formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }

    render() {
        return (
            <section className="author-area pTB100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>{this.state.initData.pre_heading}</span>
                            </div>
                            {/* Item Form */}
                            <form className="item-form no-hover" onSubmit={ this.onSubmit }>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="name" placeholder="Name" required="required" value={this.state.name} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="email" className="form-control" name="email" placeholder="Email" required="required" value={this.state.email} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <textarea className="form-control" name="message" placeholder="Message" cols={30} rows={3} defaultValue={""} value={this.state.message} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mt-3">
                                            <ReCAPTCHA theme='dark' sitekey={this.RECAPTCHA_SITE_KEY} onChange={this.handleRecaptcha} className="recaptcha"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4"><i className="icon-paper-plane mr-2" />Send Message</button>
                                    </div>
                                </div>
                            </form>
                            <p className="form-message text-center">{this.state.formMessage}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default GetInTouch;