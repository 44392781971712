import React, { Component } from 'react';

const initData = {
  itemimgc: "/imgc/R4th-bg.webp",
  title: "INVESTED BY",
  subtitle: "Our project is backed by the following venture capitals.",
}
const logo = [
  {
    "id": 1,
    "logo": "/imgc/partners/vc/logo_1.webp",
    "link": "https://afkdao.io/",
  },
  {
    "id": 2,
    "logo": "/imgc/partners/vc/logo_2.webp",
    "link": "https://www.gamerzarena.com/tournaments",
  },
  {
    "id": 3,
    "logo": "/imgc/partners/vc/logo_3.webp",
    "link": "https://aurora.ventures/",
  },
  {
    "id": 4,
    "logo": "/imgc/partners/vc/logo_4.webp",
    "link": "http://www.bmw-vc.com/",
  },
  {
    "id": 5,
    "logo": "/imgc/partners/vc/logo_5.webp",
    "link": "https://www.cabin.vc/",
  },
  {
    "id": 6,
    "logo": "/imgc/partners/vc/logo_6.webp",
    "link": "https://coinsgroup.io/",
  },
  {
    "id": 7,
    "logo": "/imgc/partners/vc/logo_7.webp",
    "link": "https://crypto-society.com/",
  },
  {
    "id": 8,
    "logo": "/imgc/partners/vc/logo_8.webp",
    "link": "https://cryptovietnam.vn/",
  },
  {
    "id": 9,
    "logo": "/imgc/partners/vc/logo_9.webp",
    "link": "https://eaglerise.capital/",
  },
  {
    "id": 10,
    "logo": "/imgc/partners/vc/logo_10.webp",
    "link": "https://www.gains-associates.com/",
  },
  {
    "id": 11,
    "logo": "/imgc/partners/vc/logo_11.webp",
    "link": "https://linktr.ee/SpykeFast",
  },
  {
    "id": 12,
    "logo": "/imgc/partners/vc/logo_12.webp",
    "link": "https://kenzoventures.io/",
  },
  {
    "id": 13,
    "logo": "/imgc/partners/vc/logo_13.webp",
    "link": "https://www.lucidblueventures.com/",
  },
  {
    "id": 14,
    "logo": "/imgc/partners/vc/logo_14.webp",
    "link": "https://oiginvest.com/",
  },
  {
    "id": 15,
    "logo": "/imgc/partners/vc/logo_15.webp",
    "link": "https://plutusvc.com/",
  },
  {
    "id": 16,
    "logo": "/imgc/partners/vc/logo_16.webp",
    "link": "https://www.seerlabs.io/",
  },
  {
    "id": 17,
    "logo": "/imgc/partners/vc/logo_17.webp",
    "link": "https://www.sharding.capital/",
  },
  {
    "id": 18,
    "logo": "/imgc/partners/vc/logo_18.webp",
    "link": "https://dextforce.net/",
  },
  {
    "id": 19,
    "logo": "/imgc/partners/vc/logo_19.webp",
    "link": "https://www.cryptomedia.id/",
  },
  {
    "id": 20,
    "logo": "/imgc/partners/vc/logo_20.webp",
    "link": "https://www.7oclockcapital.com/",
  },
  {
    "id": 21,
    "logo": "/imgc/partners/vc/logo_21.webp",
    "link": "https://www.bip32.vc/",
  },
  {
    "id": 22,
    "logo": "/imgc/partners/vc/logo_22.webp",
    "link": "https://www.vespertine.capital/",
  },
  {
    "id": 23,
    "logo": "/imgc/partners/vc/logo_23.webp",
    "link": "https://www.vendetta.capital/",
  },
  {
    "id": 24,
    "logo": "/imgc/partners/vc/logo_24.webp",
    "link": "https://aigventure.com/",
  },
  {
    "id": 25,
    "logo": "/imgc/partners/vc/logo_25.webp",
    "link": "https://www.cryptocamel.com/",
  },
  {
    "id": 26,
    "logo": "/imgc/partners/vc/logo_26.webp",
    "link": "https://dreamboatcapital.com/",
  },
  {
    "id": 27,
    "logo": "/imgc/partners/vc/logo_27.webp",
    "link": "https://oddiyana.ventures/",
  },
  {
    "id": 28,
    "logo": "/imgc/partners/vc/white/AvStar-capital.webp",
    "link": "https://avstarcapital.co/",
  },
  {
    "id": 29,
    "logo": "/imgc/partners/vc/white/HG-Ventures-Logo.png",
    "link": "https://hgventures.com/",
  },
]
const numRow = [
  {
    "id": 1,
    "title": "Invested by",
    "num": "32",
    "info": "Venture Capital",
    "link": "/investors"
  },
  {
    "id": 2,
    "title": "Reached",
    "num": "800K+",
    "info": "Users",
    "link": "",
  },
  {
    "id": 3,
    "title": "Partnered with",
    "num": "30+",
    "info": "Blockchain and Esports associations and companies at C Suite level",
    "link": "/partners"
  }
]

class InvestBy extends Component {
  state = {
    initData: {},
    numRow: [],
    logo: [],
  }
  componentDidMount() {
    this.setState({
      initData: initData,
      numRow: numRow,
      logo: logo
    })
  }
  render() {
    return (
      <section className="item-details-area p0">



        {/* <div className="row justify-content-between R4thBG">
                        

                        <div className="col-12 pb40">

                            <div className="content mt40 text-center">
                              <span className="wTitle mt50">{this.state.initData.title}</span>
                                    <p className="ptc mt50 text-center">{this.state.initData.subtitle}</p>
                            </div>
                            <div className="row  mt30 plogos">
                                            {this.state.logo.map((item, idx) => {
                                                return (
                                                  <div className="col-6 col-lg-2 mt30 text-center" key={`wd_${idx}`}>
                                                     <a href={item.link} target="_blank"><img src={item.logo} alt="GEMS" className="mAuto" /></a>
                                                  </div>
                                                );
                                            })}
                            </div>
                            
                                
                            
                        </div>
                    </div>
                    <div className="row justify-content-between plogos tWhite darkPurple">
                                
                                {this.state.numRow.map((item, idx) => {
                                                return (
                                                    <div className="col-12 col-lg-4  pTB50 text-center" key={`wd_${idx}`}>
                                                        <p>{item.title}</p>
                                                        <h1>{item.num}</h1>
                                                        <p className='investedby-descripton'>{item.info}</p>
                                                        { this.props.ishome && item.link !== "" ? <a href={item.link} className="btn ml-lg-auto btn-bordered-white">Learn More</a> : ""}
                                                    </div>
                                                );
                                  })}
                    </div> */}


        <img src='/img/tournament-platform.jpg' style={{ width: 100 + '%' }} />



      </section>
    );
  }
}

export default InvestBy;