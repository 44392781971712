import React, { Component } from 'react';
import './contactUsSection.css'
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';



class ContactUsSection extends Component {
    constructor(props) {
        super(props);
        this.state ={
            file:null,         
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
 

    selectInput = React.createRef();
    state = {
        collaboration_type: "",
        file: null
    }
    componentDidMount() {
        this.setState({
            collaboration_type: "",
            file: null,
            formMessage: "",
            recaptchaToken: ""
        })
    }
 
    handleChangeColab(collaboration_type) {
        console.log(collaboration_type)
        this.setState({ ...this.state, collaboration_type: collaboration_type })
    }
   
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    

    // UPLOAD_ENDPOINT = 'http://localhost:8888/gems%20marketing/public/assets/php/contact_us_mail.php';
    UPLOAD_ENDPOINT = '/assets/php/contact_us_mail.php';

    RECAPTCHA_SITE_KEY = "6LdQRa4hAAAAADMkyaWA-5C2CH1gDQrsA81_eAbM"; 

    handleRecaptcha = (token) => {
        this.setState({
            recaptchaToken: token
        });
    }

    async onSubmit(e){
            e.preventDefault() 
            let res = await this.uploadFile(this.state.file, this.state.fullname,this.state.job_title,this.state.telegram,this.state.company_name,this.state.company_website,this.state.company_description,this.state.collaboration_type, this.state.recaptchaToken);
            this.setState({
                formMessage: res.data
            });
            if(res.data=='Thank you your message, our team will be in touch soon'){
                this.setState({
                    file: '',
                    fullname: '',
                    job_title: '',
                    telegram: '',
                    company_name: '',
                    company_description: '',
                    company_website: '',
                    collaboration_type:'',
                    recaptchaToken:''
                    
                });
            }
        }
        onChange(e) {
            this.setState({file:e.target.files[0]})
        }
   
        async uploadFile(file, fullname, job_title, telegram, company_name, company_website, company_description, collaboration_type, recaptchaToken){
            const formData = new FormData();      
            formData.append('fileToUpload',file)
            formData.append('name',fullname)
            formData.append('job_title',job_title)
            formData.append('telegram',telegram)
            formData.append('company_name',company_name)
            formData.append('company_website',company_website)
            formData.append('company_description',company_description)
            formData.append('collaboration_type',collaboration_type)       
            formData.append('recaptchaToken',recaptchaToken)       
            return  await axios.post(this.UPLOAD_ENDPOINT, formData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
          }

          
    render() {
     
            
        return (
           
            <section className="author-area ">
                <div className="container" id='contactsection-container'>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>Contact Us</span>
                            </div>
                            {/* Item Form */}
                            
                            <form className="item-form no-hover justify-content-center" onSubmit={ this.onSubmit }>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="fullname" placeholder="Name" required="required" value={this.state.fullname} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="job_title" placeholder="Job Title" required="required" value={this.state.job_title} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="telegram" placeholder="Telegram ID" required="required"  value={this.state.telegram} onChange={this.handleInputChange}  />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="company_name" placeholder="Company Name" required="required" value={this.state.company_name} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="company_website" placeholder="Company Website" required="required" value={this.state.company_website} onChange={this.handleInputChange} />
                                        </div>
                                    </div>

                                    <div className="col-12 ">
                                        <div className="form-group mt-3">
                                            <textarea className="form-control" name="company_description" placeholder="Company Description" maxLength={500} cols={30} rows={3} defaultValue={""} value={this.state.company_description} onChange={this.handleInputChange}  />
                                        </div>
                                    </div>
                                    <div className="col-12 row" id='dropdown-menu'>
                                        <div title='Types of Collaborations' className="btn-group col-12 ">
                                            <button type="button" className="btn btn-danger colab-name">
                                                <span>{this.state.collaboration_type || "Types of Collaborations"}</span>
                                            </button>
                                            <button type="button" className="btn btn-danger dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="sr-only">Toggle Dropdown</span>
                                            </button>
                                            <div id='drop' className="dropdown-menu">
                                                <div className="dropdown-item" onClick={() => this.handleChangeColab("Partnership")} href="#drop">Partnership </div>
                                                <div className="dropdown-item" onClick={() => this.handleChangeColab("Listings")} href="#drop">Listings</div>
                                                <div className="dropdown-item" onClick={() => this.handleChangeColab("Esports Tournament")} href="#drop">Esports Tournament</div>
                                                <div className="dropdown-item" onClick={() => this.handleChangeColab("Marketing")} href="#drop">Marketing</div>
                                                <div className="dropdown-item" onClick={() => this.handleChangeColab("Others")} href="#drop">Others</div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="text" style={{ visibility: 'hidden', width: 0, height: 0 }} value={this.state.collaboration_type} className="form-control" name="collaboration_type" placeholder="Collaboration Type" />
                                    <div className="row col-12">
                                        <div onClick={() => { this.selectInput.current.click() }} id='upload-container' className="btn-group col-12" >
                                            <div className="btn btn-danger">
                                                <label>Upload File</label>
                                            </div>
                                            <button className='btn btn-dange file-name'><span>{this.state.file ? this.state.file.name : "No File Selected"}</span></button>
                                        </div>
                                    </div>
                                    <input onChange={(e) => this.setState({ ...this.state, file: e.target.files[0] })} type="file" ref={this.selectInput} style={{ visibility: 'hidden', width: 0, height: 0 }} className="form-control" name="fileToUpload" placeholder="" />
                                    <br />
                                    <div className="recaptcha-wrapper">
                                        <ReCAPTCHA theme='dark' sitekey={this.RECAPTCHA_SITE_KEY} onChange={this.handleRecaptcha} className="recaptcha"/>
                                    </div>
                                    <div style={{ marginTop: '10px' }} className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" ><i className="icon-paper-plane mr-2" />Send Message</button>
                                    </div>
                                </div>
                            </form>
                            <p className="form-message text-center">{this.state.formMessage}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactUsSection;