import React, { Component } from 'react';

const initData = {
    title: "GEMS ADVISOR",
}
const advisor = [
    {
        "id": 1,
        "imgc": "/imgc/founder/advisor/Steve.webp",
        "name": "Steve Good",
        "title": "CEO, Dreams Quest", 
    },
    {
        "id": 2,
        "imgc": "/imgc/founder/advisor/Paulii.webp",
        "name": "Paulii Good",
        "title": "CMO, Dreams Quest",
    },
    {
        "id": 3,
        "imgc": "/imgc/founder/advisor/Charlie_Polygon.webp",
        "name": "Charlie Hu",
        "title": "Managing Partner of LucidBlue Ventures, Founder of MetaCelium",
    },
    {
        "id": 4,
        "imgc": "/imgc/founder/advisor/Kelvin.webp",
        "name": "Kelvin Chua",
        "title": "CEO, SkyArk Studio",
    },
    {
        "id": 5,
        "imgc": "/imgc/founder/advisor/Waleed.webp",
        "name": "Waleed Rasuli",
        "title": "MD, Tezos",
    },
    {
        "id": 6,
        "imgc": "/imgc/founder/advisor/Liko.webp",
        "name": "Liko Subakti",
        "title": "CEO, Project SEED",
    },
    {
        "id": 7,
        "imgc": "/imgc/founder/advisor/Daniel.webp",
        "name": "Daniel Daboczy",
        "title": "CEO, Influencio",
    },
    {
        "id": 8,
        "imgc": "/imgc/founder/advisor/Tom.webp",
        "name": "Tom Tirman",
        "title": "CEO, PARSIQ / IQ Protocol",
    }
    
]
class Team extends Component {
    state = {
        initData: {},
        advisor: [],
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            advisor: advisor
        })
    }
    render() {
        return (
            <section className="item-details-area p0">
                    <div className="row justify-content-between">
                        
                        <div className="col-12 pb40 mt100">
                            {/* Content */}
                            <div className="intro text-center">
                                <div className="intro-content">
                                    <span>{this.state.initData.title}</span>
                                </div>
                            </div>
                            <div className="row justify-content-between mt30">
                                            {this.state.advisor.map((item, idx) => {
                                                return (
                                                    <div key={`wd_${idx}`}  className="col-12 col-sm-6 col-lg-3 item text-center pfm">
                                                        <img src={item.imgc} alt={item.name}  className="founders" />
                                                        <h2 className="Mname mt30">{item.name}</h2>
                                                        <p className="m0">{item.title}</p>
                                                    </div>
                                                );
                                            })}
                            </div>
                        </div>
                    </div>
            </section>
        );
    }
}

export default Team;