import React, { Component } from 'react';

import Header from '../components/Header/Header';
import TeamHero from '../components/TeamHero/TeamHero';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import ContactUsSection from '../components/ContactUs/contactUsSection';

class ContactUs extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <TeamHero />
                <ContactUsSection />
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default ContactUs;