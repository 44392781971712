import React, { Component } from 'react';

class Terms extends Component {
    render() {
      return (
        <section className="section-text py-5">
          <div className="container">
            <div className="article">
              <h2 className="text-center mb-4">Terms of Use</h2>
              <div className="article-body">
                <h4>Last updated: 27th August 2022</h4>
                <p>WELCOME TO GEMS. PLEASE SEND A FEW MINUTES TO READ THESE TERMS OF USE (<strong>“TERMS”</strong>) CAREFULLY BEFORE USING ANY OF OUR SITES, APPLICATIONS AND/OR SOFTWARE (COLLECTIVELY, THE <strong>“APPLICATIONS”</strong>) OFFERED BY GEMS TECHNOLOGY LIMITED, ITS SUBSIDIARIES, PARENT COMPANIES, AFFILIATES, JOINT VENTURES AND OTHER CORPORATE ENTITIES UNDER COMMON OWNERSHIP (<strong>“GEMS”</strong>, <strong>“WE”</strong>, <strong>“OUR”</strong> OR <strong>“US”</strong>) . BY DOWNLOADING, USING, OR ACCESSING OUR APPLICATIONS, AND/OR ANY DATA OR CONTENT THAT IS ACCESSED, CONTAINED, OR UTILIZED IN OR BY THE APPLICATIONS IN ANY MANNER, YOU AGREE THAT YOU HAVE READ AND CONSENT TO BE BOUND BY AND ARE A PARTY TO THE TERMS TO THE EXCLUSION OF ALL OTHER TERMS.  THE TERMS MAY BE AMENDED FROM TIME TO TIME BY US. THE TERMS ALSO INCLUDE OUR PRIVACY POLICY AND OTHER LEGAL NOTICES, WHICH ARE INCORPORATED HEREIN BY REFERENCE.  IF YOU DO NOT UNCONDITIONALLY AGREE TO BE BOUND BY THE TERMS, DO NOT USE THE APPLICATIONS, AND YOU MUST UNINSTALL THE APPLICATIONS FROM ALL OF YOUR DIGITAL DEVICE(S) IMMEDIATELY.</p>
                <h4>Table of Contents</h4>
                  <ol>
                    <li>Access to the Applications</li>
                    <li>Ownership of Intellectual Property</li>
                    <li>In-Applications Currencies/Goods</li>
                    <li>User Generated Content</li>
                    <li>Posting on Other Websites</li>
                    <li>Usage Rules</li>
                    <li>Protection of Personal Information</li>
                    <li>Disputes with Other Users</li>
                    <li>Disclaimer of Warranties</li>
                    <li>Limitation of Liability</li>
                    <li>Indemnification</li>
                    <li>Fees and Payment  </li>
                    <li>Third Party Services and Websites</li>
                    <li>Termination</li>
                    <li>Dispute Settlement</li>
                    <li>Miscellaneous</li>
                  </ol>
                <h4>1. ACCESS TO THE APPLICATIONS</h4>
                <p>1.1  Subject to your acceptance of and continuing compliance with the Terms, we grant you a non-exclusive, non-transferable, non-assignable (without right to sublicense) and revocable limited license to use the Applications solely in machine executable object code form (excluding source code) for your personal, non-commercial use, and not for the benefit of any third party, on your digital device(s) of which you are the primary user.  We own and operate the Applications.  You agree not to use the Applications for any other purpose, or to copy, reproduce, alter, modify or distribute the content of the Applications except as specifically permitted herein. We reserve all rights not granted herein.</p>
                <p>1.2  We reserve the right, at our sole discretion, to add, change, modify or remove portions of the Terms in any way from time to time. </p>
                <p>1.3  We will notify you of any modifications to the Terms by posting them on our website.  You agree that you will periodically check our website for updates to the Terms. You agree that you will be considered to have been given notice of any modifications once we post them on our website and that your continued use of the Applications after such notice is posted shall be deemed your acceptance of the Terms as modified.</p>
                <p>1.4  If at any point you do not agree to any portion of the then-current version of the Terms, the Privacy Policy or our other policy, rules or codes of conduct in relation to your use of the Applications, your license to use the Applications shall immediately be terminated and you must immediately cease using the Applications.</p>
                <p>1.5  We reserve the right to suspend or discontinue the Applications or to modify the Applications’ content in any way and at any time without liability, with or without notice to you.</p>
                <p>1.6  We may also impose limitations on certain features and services or restrict your access to portions or all of the Applications without notice or liability.</p>
                <p>1.7  You also agree to be bound by any application, forum, or specific rules published within the Applications.</p>
                <p>1.8  Your use of the Applications is conditioned upon your compliance with the Terms, and any use of the Applications in violation of the Terms will be regarded as an infringement of our intellectual property rights in and to the Applications.  We reserve the right to terminate your access to the Applications without notice if you violate any of the Terms.</p>
                <p>1.9  You represent and warrant that (i) you are an individual (not a corporation) and are 13 years old or older; if you are between the ages of 13 and 18, you represent that you have obtained the consent of your parent or legal guardian and the Terms have been reviewed and agreed by them; (ii) all registration information you submit is accurate and truthful; and (iii) you will maintain the accuracy of such information.  You also certify that you are legally permitted to use and access the Applications and are fully responsible for the selection and use of and access to the Applications.  The Terms are void where prohibited by law, and the right to access the Applications is revoked in such jurisdictions.</p>
                <h4>2. OWNERSHIP OF INTELLECTUAL PROPERTY</h4>
                <p>2.1  Unless otherwise specified in writing, all materials that are part of the Applications (including without limitation any animations, artwork, audio-visual effects, catch phrases, character names, character profile information, characters, computer code, concepts, dialogue, documentation, game titles, games, graphics, illustrations, images, methods of operation, moral rights, musical compositions, objects, photographs, sounds, stories, text and themes) are owned, controlled or licensed by us and are protected by law from unauthorised use. All contents of the Applications are protected by international copyright laws.  “GEMS” and all names and logos of Applications or games published by us are trademarks owned by us and may not be used without our express written consent.  We reserve all rights, including without limitation, all intellectual property rights or other proprietary rights, in connection with the Applications.  All trademarks not owned by us that appear in the Applications are their respective owners’ property, who may or may not be affiliated with, connected to, or sponsored by us.</p>
                <p>2.2  You do not acquire any of our ownership rights by using the Applications, downloading material from or uploading material to the Applications, or by purchasing any virtual goods therein.</p>
                <p>2.3  You shall comply with all trademark rules, copyright notices, information and restrictions contained in any content accessed through the Applications.  You agree not to broadcast, copy, create derivative works based on, display, license, modify, participate in the transfer or sale of, perform, publish, redistribute, reproduce, translate, transmit, upload or otherwise exploit for any purposes whatsoever any content or material from the Applications, except as expressly permitted herein, without our express prior written consent.</p>
                
                <h4>3. IN-APPLICATIONS CURRENCIES/GOODS</h4>
                <p>3.1  The Applications may include a virtual, in-application currency (<strong>“Virtual Currency”</strong>) including, but not limited to cash, chips, coins or points, that may be purchased from us if you are a legal adult in your jurisdiction. The Applications may also include virtual, in-application digital items (<strong>“Virtual Goods”</strong>) that may be purchased from us for Virtual Currency.  Our Virtual Currency has no monetary value and does not constitute currency or property of any type.  Regardless of the terminology used, Virtual Currency and Virtual Goods may never be redeemed for “real world” money, goods or other items of monetary value from us or any other party. You acknowledge that your purchase of the Virtual Currency or Virtual Goods is final and is not refundable, exchangeable or transferable, in particular when we cease making the Applications available, whether such action is taken at our sole discretion or due to unforeseen events.</p>
                <p>3.2  The price to be paid by you is the price indicated on the applicable website for the Applications or within the Applications. When you purchase a license to use our Virtual Currency or Virtual Goods, or purchase a subscription to use an Application, you agree to pay the applicable taxes that we or our agent assesses on your purchase. We reserve the right to amend the price and specifications shown in relation to any Applications, any subscription, Virtual Currency and Virtual Goods.  Where applicable, we will also state the period for which the relevant offer or price remains valid.  If you decide not to complete a purchase, you should, if enabled by your digital device’s operating system, select “no” or close the window that requests your confirmation. Otherwise, to the extent the operating system, distributor and other factors permit, we may be able to provide you with a credit against your future purchases; we do not offer any returns or cancellations of purchases.</p>
                <p>3.3  If you wish to purchase a subscription and you later decide to cancel it, you must contact your Application distributor for its cancellation policies. Your cancellation may not become effective for a certain period of time after your cancellation is communicated to them and is subject to the terms of service, terms of use or similar user agreement of your Application distributor.</p>
                <p>3.4  You may only use the services of any billing and payment provider identified on the applicable website or in the applicable Application to pay for the Virtual Currency.  Until the billing and payment provider has authorized the use of your credit card or other applicable method for payment, we will not supply any products or services to you.  You will be bound by such third party provider’s terms and conditions when using the billing and payment provider’s services.  You may be required to create an account with the billing and payment provider, and to provide that provider with your bank account or credit or debit card details. Costs, specifically data charges and related tariffs, associated with accessing and using the Applications depends on your internet service provider and/or wireless carrier.  Please refer to your provider’s terms and conditions for details.</p>
                <p>3.5  Virtual Currency may only be held by legal residents of countries where access to and use of the Applications is permitted. A license to use Virtual Currency in our Applications may be purchased or acquired only from us through means we provide on the applicable website(s) or applicable Applications or otherwise expressly authorize. We reserve the right to limit, refuse or block your request(s) to purchase and/or acquire a license to use Virtual Currency in our Applications for whatever reason.</p>
                <p>3.6  Any Virtual Goods and/or Virtual Currency balance shown in your account does not constitute a “real world” balance or reflect any stored value. We have the absolute right to manage, regulate, control, modify and/or eliminate such Virtual Goods and/or Virtual Currency as we see fit in our sole discretion, and we are not liable to you or anyone for the exercise of such rights.  Prices and availability of Virtual Goods and/or Virtual Currency are subject to change without notice to you and we are not liable to you or any third party for the exercise of such rights.</p>
                <p>3.7  You agree that all sales of Virtual Goods and Virtual Currency are final. No refunds will be given, except in our sole and absolute discretion.  If your account is terminated or suspended for any reason or if we discontinue providing the Applications, all Virtual Goods and Virtual Currency will be forfeited at our sole and absolute discretion.</p>
                <h4>4. USER GENERATED CONTENT</h4>
                <p>4.1  The Applications may invite you to chat or participate in blogs, message boards, online forums and other functionality and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute or broadcast content and materials to us and/or to or via the Applications, including, without limitation, comments, data, graphics, images, personal information, photographs, sounds, suggestions, text, writings or other material (collectively <strong>“User Content”</strong>).  Any material you transmit to us will be treated as non-confidential and non-proprietary. We cannot guarantee that other users will not use the ideas and information that you share.  Therefore, if you have an idea or information that you would like to keep confidential and/or do not want others to use, do not post it on the Applications.  We shall not be responsible for evaluating, using or compensating you for any ideas or information that you may choose to submit.</p>
                <p>4.2  If you submit suggestions, proposals, comments or other materials (collectively <strong>“Submissions”</strong>) within the Applications you understand and agree that we (i) have no obligation to keep your Submissions confidential; (ii) have no obligation to return your Submissions or respond in any way; and (iii) may use your Submissions for any purpose in any way without notice or compensation to you.  We are not responsible for other user’s misuse or misappropriation of any content or information you post in any forums, blogs and chat rooms.</p>
                <p>4.3 You are solely responsible for the information that you post on, through or in connection with the Applications and that you provide to others.  You represent and warrant that your User Content is wholly original to you and you exclusively own the rights to your User Content, including the right to grant all of the rights and licenses in the Terms without incurring us any third party obligations or liability arising out of its exercise of the rights thereto granted herein by you.</p>
                <p>4.4  By submitting or transmitting any User Content while using the Applications, you affirm, represent and warrant that such submission or transmission is (i) accurate and not confidential; (ii) free of viruses, adware, spyware, worms or other malicious code; and (iii) not in violation of any applicable laws, contractual restrictions or other third party rights, and that you have permission from any third party whose personal information or intellectual property are in the User Content.</p>
                <p>4.5  You grant to us the unrestricted, unconditional, unlimited, worldwide, irrevocable, transferable, perpetual fully-paid up and royalty-free, non-exclusive and unlimited right and license to adapt, archive, broadcast, cache copy, commercialize, create derivative works of, disclose, distribute, enter into computer memory, excerpt, fix, host, improve, introduce into circulation, lease, manufacture, modify, publicly display, publicly perform, publish, re-format, re-title, rent, reproduce, resell, sell, store, sublicense, transfer, translate, transmit, use, or otherwise exploit in any manner whatsoever, all or any portion of your User Content to which you have contributed, for any purpose whatsoever, in any and all formats, on or through any and all media, software, formula or medium now known or hereafter known or discovered, and with any technology or devices now known or hereafter developed and to advertise, market and promote the same.</p>
                <p>4.6  To the extent permitted by applicable laws, you hereby waive any moral rights you may have in any User Content.</p>
                <p>4.7  The license you grant us to use your User Content terminates when you remove or delete your User Content or you close your account, unless your User Content has been shared with others, and they have not removed or deleted it.  However, you understand and accept that any removed or deleted content may remain in back-up copies for a reasonable period of time.</p>
                <p>4.8  We have no obligation to monitor any User Content or enforce any intellectual property rights that may be associated with your User Content, but we are entitled to enforce such rights through any means as we deem fit, including taking and controlling actions on your behalf.   We reserve the right to limit the storage capacity of the User Content that you post on, through or in connection with the Applications.</p>
                <p>4.9  We have no obligation to accept, display, review, monitor or maintain any User Content.  If we choose at any time, in our sole discretion, to monitor the Applications, we have the right, in our sole discretion, to remove or delete User Content from the Applications without notice to you for whatever reason at any time.  By entering into the Terms, you hereby provide your irrevocable consent to such monitoring and recording.  We may move, re-format, edit, alter, distort, remove or refuse to exploit User Content without notice to you and without liability; provided, however, that we reserve the right to treat User Content as content stored at the direction of users for which we will not exercise editorial control except to enforce the rights of third parties and the Content Restrictions set forth in Section 6 below when violations are brought to our attention.</p>
                <p>4.10  You acknowledge that you do not rely on us to monitor or edit the Applications and that the Applications may have content which you find offensive and you hereby waive any objections you might have with respect to viewing such content.</p>
                <p>4.11  You also understand that we cannot guarantee (i) the identity of any other users with whom you may interact in the course of using the Applications; and (ii) the authenticity of any data which users may provide about themselves.  You acknowledge that all content accessed by you using the Applications is at your own risk and you will be solely responsible for any damage or loss to any party resulting therefrom.</p>
                <h4>5. POSTING ON OTHER WEBSITES</h4>
                <p>5.1  Subject to the Terms, we agree to grant you a limited, revocable, non-exclusive, non-transferable license to post an image of your personal avatar and/or screen shot from your account within the Applications and any other materials that we specifically authorize in writing may be posted, for non-commercial purposes only, on other websites, on your own personal website or on a third party website that permits posting of content at the direction of users, provided that such third party website (i) is not our commercial competitor, (ii) does not criticize, or take other actions that could reasonably expected to result in harm to, us, (iii) does not obtain any rights to such posted content other than a non-exclusive license to post it at your direction, (iv) does not charge for access to such posted content and does not associate products, services or advertising with such posted content, and (v) together with all website to which it links, complies with all applicable laws, and does not in any way abuse, defame, stalk, threaten or violate the rights of privacy, publicity, intellectual property or other rights of any kind of any third party or, in any way, post, publish, distribute, disseminate or facilitate any inappropriate, infringing, defamatory, profane, indecent, obscene, illegal/unlawful or otherwise objectionable contents, information, topic, name or other material (an <strong>“Authorised Website”</strong>). All of our rights and remedies are expressly reserved, and we may revoke this limited license, in whole or in part, without notice.</p>
                <p>5.2  Without limitation to the generality of Section 5.1, the following terms and conditions apply to your posting of a copy of your avatar and/or screen shots on an Authorised Website:</p>
                <ul>
                  <li>you must display a prominent link to the Application’s homepage in connection with any of your use of the content or material permitted hereunder, including, without limitation, in e-mails you are sending friends.</li>
                  <li>you agree to include, and not remove or alter, our trademark, copyright, intellectual property rights or other proprietary rights notices, as provided by us on the Applications and within e-mail page(s), when displaying an avatar or images from our Applications, and you agree to comply with usage guidelines that may be provided by us from time to time. You agree that all goodwill that arises in connection with your use of our trademarks inures exclusively to us, and you agree not to challenge our ownership or control of any of our trademarks, nor use or adopt any trademarks that might be confusingly similar to such of our trademarks.</li>
                </ul>
                
                <h4>6. USAGE RULES</h4>
                <p>6.1  As a condition of your use of and access to the Applications, you agree to comply with these Usage Rules, which are provided as an example rather than as a limitation, and any application or game specific rules published within the Applications.  These Usage Rules are not meant to be exhaustive, and it is at our sole discretion to determine what conduct it considers to be a violation of the Terms or improper use of the Applications and to take action up to and including termination of your account and exclusion from further participation in the Applications.  We may also post additional rules that apply to your conduct during your use of and access to the Applications.</p>
                <p>6.2  Any use of the Applications in violation of these Usage Rules, as determined by us in our sole discretion, is strictly prohibited, and can result in the immediate revocation of your limited license granted under Section 1, and you may be liable for violations of law.  Any attempt by you to disrupt or interfere with the Applications, including undermining or manipulating the legitimate operation of any of the Applications is a violation of our policy and may constitute a violation of criminal and civil laws.</p>
                <p>6.3  You agree that your use of and conduct on the Applications shall be lawful and your User Content will not:</p>
                <ul>
                  <li>include profanity or any obscene, indecent, pornographic, sexual or otherwise objectionable content or language.</li>
                  <li>include any offensive comments that are connected to gender, national origin, physical handicap race or sexual preference; hate speech is strictly not tolerated.</li>
                  <li>promote violence or describe how to perform a violent act.</li>
                  <li>abuse defame, disparage, embarrass, harass, intimidate, libel, mock, ridicule, spam, threaten or do anything else to anyone that is unwanted.</li>
                  <li>violate the contractual, personal, intellectual property or other rights of any party, or promote or constitute illegal activity.</li>
                  <li>be in violation of the Terms or the Applications’ rules of conduct.</li>
                </ul>
                <p>(collectively “Content Restrictions”).</p>
                <p>6.4  You also agree that you will not, under any circumstances:</p>
                <p><u>General</u></p>
                <ul>
                  <li>use abusive, offensive, or defamatory screen names and/or personas.</li>
                  <li>engage in cheating or any other activity deemed by us to be in conflict with the spirit or intent of the Applications, including but not limited to circumventing or manipulating the Terms, our rules, mechanics or policies.</li>
                  <li>make improper use of our support services or submit false reports of abuse or misconduct.</li>
                  <li>use the Applications, intentionally or unintentionally, in connection with any violation of the Terms or any applicable law, rule or regulation or any other requirements or restrictions posted by us on the Applications, or do anything that promotes the violation of the above.</li>
                </ul>
                <p><u>Cheating and Hacking</u></p>
                <ul>
                  <li>promote, encourage or take part in any activity involving hacking, cracking, phishing, taking advantage of exploits or cheats and/or distribution of counterfeit software and/or Virtual Goods or Virtual Currency.</li>
                  <li>attempt to impersonate any other person, indicate falsely that you are our employee or representative, or attempt to mislead users by indicating that you represent us or any of our partners or affiliates.</li>
                  <li>trick, defraud or mislead us or other users of the Applications, especially in any attempt to learn sensitive account information such as passwords.</li>
                  <li>create user accounts by automated means or under false pretences or mislead others as to the origins of your communications.</li>
                  <li>use any cheating/hacking/altering software or tools.</li>
                  <li>copy or adapt the Application’s software including but not limited to Flash, HTML, JavaScript, PHP or other code.</li>
                  <li>except as may be the result of standard search engine or web browser usage, use or launch, develop or distribute any automated system, including, without limitation, any cheat utility, robot (or <strong>“bot”</strong>), spider, scraper or offline reader that accesses the Applications, or use or launch any unauthorised script or other software.</li>
                  <li>block or obscure any notice, banner or advertisement on the Applications.</li>
                  <li>disguise the source of your User Content or other information you submit to the Applications or use tools which anonymise your internet protocol address (e.g. anonymous proxy) to access the Applications.</li>
                  <li>disrupt, overburden, or aid or assist in the disruption or overburdening of (i) any computer or server used to offer or support the Applications (each a <strong>“Server”</strong>); or (ii) the enjoyment of the Applications by any other person.</li>
                  <li>upload any software or content that you do not own or have permission to freely distribute.</li>
                </ul>
                <p>Commercial Activity</p>
                <ul>
                  <li>sell the Applications or any part thereof including but not limited to Virtual Goods or Virtual Currency, user accounts and access to them in exchange for real currency or items of monetary value.</li>
                  <li>post or transmit unsolicited advertising, promotional materials or other forms of solicitation in-application or in the forums.</li>
                  <li>engage in any commercial activities, including, without limitation, any attempt to raise money for anyone or advertise or promote a product, service, website, pyramid scheme or other multi-tiered marketing scheme.</li>
                  <li>post messages for any purpose other than personal communication, or transmit unauthorized communications through the Applications, including advertising or promotional messaging, chain letters, pyramid schemes, or other commercial activities.</li>
                </ul>
                <p><u>Unauthorised Use or Connection to the Applications</u></p>
                <ul>
                  <li>upload, disseminate or transmit (or attempt to upload, disseminate, or transmit) adware, bombs, cancelbots, corrupted data, keyboard loggers, time spyware, Trojan horses, viruses, worms or any other malicious or invasive code or program or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text) that interferes with any person’s uninterrupted use and enjoyment of the Applications and User Content or alters, disrupts, impairs, interferes with or modifies the use, features, functions, operation or maintenance of the Applications or the User Content.</li>
                  <li>decompile, reverse assemble, reverse engineer, modify or attempt to discover any software (source code or object code) that the Applications create to generate web pages or any software or other products or processes accessible through the Applications.</li>
                  <li>disparage, tarnish, or otherwise harm, in our opinion, us and/or the Applications.</li>
                  <li>interfere or attempt to interfere with the proper functioning of the Applications or connect to or use the Applications in any way not expressly permitted by the Terms.</li>
                  <li>attempt to gain unauthorized access to the Applications, other party’s registered accounts or to the computers, Servers, or networks connected to the Applications by any means other than the user interface provided by us, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that is part of the Applications.</li>
                  <li>interfere with or circumvent any security feature of the Applications or any feature that restricts or enforces limitations on use of or access to the Applications or User Content.</li>
                  <li>make any automated use of the system, or take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large load on our infrastructure.</li>
                  <li>bypass any robot exclusion headers or other measures we employ to restrict access to the Applications or use any software, technology, or device to send content or messages, scrape, spider, or crawl the Applications, or harvest or manipulate data.</li>
                  <li>use any unauthorized third party software that accesses, intercepts, “mines”, or otherwise collects information from or through the Applications or that is in transit from or to the Applications, including, without limitation, any software that reads areas of RAM or streams of network traffic used by the Applications to store information; we may, at our sole and absolute discretion, allow the use of certain third party user interfaces.</li>
                  <li>copy, modify or distribute rights or content from the Applications, or our copyrights or trademarks or use any method to copy or distribute the content of the Applications except as specifically allowed in the Terms.</li>
                  <li>use, facilitate, create, or maintain any unauthorized connection to the Applications, including without limitation (i) any connection to any unauthorized server that emulates, or attempts to emulate any part of the Applications; or (ii) any connection using programs, tools, or software not expressly approved by us.</li>
                  <li>intercept, examine or otherwise observe any proprietary communications protocol used by a client, a Server, or the Applications, whether through the use of a network analyzer, packet sniffer or other device.</li>
                </ul>
                <p>Collection and Publication of Personal Information</p>
                <ul>
                  <li>collect or reveal through the Applications any personal information (whether in text, image or video form) about another individual, including another person’s address, phone number, e-mail address, credit card number, financial information or any information that may be used to track, contact or impersonate that individual.</li>
                  <li>solicit or attempt to solicit personal information from other users of the Applications.</li>
                  <li>upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism.</li>
                </ul>
                <p>6.5  As we do not control or endorse the content, messages or information found in User Content portions of the Applications or external sites that may be linked to or their forums, we specifically disclaim any responsibility with regard thereto.  However, we reserve the right to access and/or record any online activity during your use of and access to the Applications and you hereby consent to our access and record of your activities.  We also reserve the right to remove any of the User Content at our sole discretion.</p>
                <h4>7. PROTECTION OF PERSONAL INFORMATION</h4>
                <p>For information regarding our treatment of your personally identifiable information, please review our current Privacy Policy. Your acceptance of the Terms shall constitute your acceptance and agreement to be bound by our Privacy Policy.</p>
                <h4>8. DISPUTES WITH OTHER USERS</h4>
                <p>You are solely responsible for your interaction with other users of the Applications and other parties that you come in contact with through the Applications.  We hereby disclaim any and all liability to you or any third party relating to your use of the Applications.  We reserve the right, but have no obligation, to monitor and/or manage disputes between you and other users of the Applications. If you have a dispute with other users or other parties, you agree to release us and indemnify us from any claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such dispute.</p>

                <h4>9. DISCLAIMER OF WARRANTIES</h4>
                <p>WE HAVE NO SPECIAL RELATIONSHIP WITH OR FIDUCIARY DUTY TO YOU.  YOU ACKNOWLEDGE THAT WE DO NOT HAVE CONTROL OVER OR DUTY TO TAKE ANY ACTION REGARDING: WHICH USERS GAIN ACCESS TO THE APPLICATIONS; WHAT CONTENT YOU ACCESS THROUGH THE APPLICATIONS; HOW YOU MAY INTERPRET, USE OR BE AFFECTED BY THE APPLICATIONS OR THE CONTENT ACCESSED THEREIN; OR WHAT ACTIONS YOU MAY TAKE AS A RESULT OF HAVING BEEN EXPOSED TO THE APPLICATIONS.  YOU HEREBY RELEASE US FROM ALL LIABILITY FOR YOU HAVING ACQUIRED OR NOT ACQUIRED ANY CONTENT THROUGH THE APPLICATIONS.  THE APPLICATIONS MAY CONTAIN, OR DIRECT YOU TO WEBSITES CONTAINING, INFORMATION THAT SOME PEOPLE MAY FIND OFFENSIVE OR INAPPROPRIATE.  WE MAKE NO REPRESENTATIONS OR WARRANTIES CONCERNING ANY CONTENT CONTAINED IN OR ACCESSED THROUGH THE APPLICATIONS, AND WE WILL NOT BE RESPONSIBLE OR LIABLE FOR THE ACCURACY, COPYRIGHT COMPLIANCE, LEGALITY OR DECENCY OF MATERIAL CONTAINED IN OR ACCESSED THROUGH THE APPLICATIONS.</p>
                <p>TO THE MAXIMUM EXTENT PERMITTED BY ANY APPLICABLE LAW, YOU EXPRESSLY AGREE THAT THE USE OF THE APPLICATIONS IS AT YOUR SOLE RISK.  THE APPLICATIONS ARE PROVIDED ON AN “AS IS” BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE, QUALITY, FUNCTIONALITY, AVAILABILITY OR PERFORMANCE OR IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, UNLESS SUCH WARRANTIES ARE LEGALLY INCAPABLE OF EXCLUSION.  WE ARE NOT LIABLE FOR INABILITY TO OBTAIN OR USE ANY CONTENT, ENTITLEMENTS, GOODS OR SERVICES.  WE PROVIDE THE APPLICATIONS ON A COMMERCIALLY REASONABLE BASIS AND DO NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE THE APPLICATIONS AT TIMES OR LOCATIONS OF YOUR CHOOSING, OR THAT WE WILL HAVE ADEQUATE CAPACITY FOR THE APPLICATIONS AS A WHOLE OR IN ANY SPECIFIC GEOGRAPHIC AREA.  WITHOUT LIMITING THE FOREGOING, NEITHER WE NOR OUR AFFILIATES OR SUBSIDIARIES, OR ANY OF THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, ATTORNEYS, THIRD-PARTY CONTENT PROVIDERS, DISTRIBUTORS, LICENSEES OR LICENSORS (COLLECTIVELY, <strong>“OUR PARTIES”</strong>) WARRANT THAT THE APPLICATIONS WILL BE UNINTERRUPTED OR ERROR-FREE.</p>

                <h4>10. LIMITATION OF LIABILITY</h4>
                <p>TO THE MAXIMUM EXTENT PERMITTED BY ANY APPLICABLE LAW, YOU ACKNOWLEDGE AND AGREE THAT THE DISCLAIMERS OF WARRANTIES CONTAINED HEREIN APPLY TO ANY AND ALL DAMAGES OR INJURY WHATSOEVER CAUSED BY OR RELATED TO THE SUBJECT MATTER OF THE TERMS AND THE USE OF, OR INABILITY TO USE, THE APPLICATIONS UNDER ANY CAUSE OR ACTION WHATSOEVER OF ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR STRICT LIABILITY AND THAT OUR PARTIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING FOR LOSS OF PROFITS, GOODWILL OR DATA, IN ANY WAY WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE APPLICATIONS.</p>
                <p>YOU FURTHER SPECIFICALLY ACKNOWLEDGE THAT OUR PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD OUR PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE APPLICATIONS AND OPERATORS OF EXTERNAL WEBSITES, AND THAT THE RISK OF THE APPLICATIONS AND EXTERNAL WEBSITES AND OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU.</p>
                <p>TO THE MAXIMUM EXTENT PERMITTED BY ANY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL OUR PARTIES BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID US IN THE NINETY (90) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM.  YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID US ANY AMOUNTS IN SUCH PERIOD, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US IS TO STOP USING THE APPLICATIONS AND TO CANCEL YOUR ACCOUNT.</p>
                <p>WHILE WE USE COMMERCIALLY REASONABLE MEANS TO PROTECT YOUR PERSONAL INFORMATION, WE ASSUME NO LIABILITY FOR DATA LOSS OR COST OF PROCUREMENT OF SUBSTITUTE GOOD OR SERVICES, DAMAGE CAUSED TO YOUR SOFTWARE OR HARDWARE, ANY OTHER LOSS OR DAMAGE SUFFERED BY YOU OR ANY THIRD PARTY, AND ANY MATTER BEYOND OUR REASONABLE CONTROL (INCLUDING WITHOUT LIMITATION ANY DAMAGE YOU MAY SUFFER BY USING THE APPLICATIONS WHILE OPERATING A MOTOR VEHICLE, IN VIOLATION OF THE TERMS, ACTS OF GOD, WAR, TERRORISM, RIOTS, EMBARGOES, ACTS OF CIVIL OR MILITARY AUTHORITIES, FIRE, FLOODS, PANDEMIC, ACCIDENTS, NETWORK INFRASTRUCTURE FAILURES, STRIKES, OR SHORTAGES OF TRANSPORTATION FACILITIES, FUEL, ENERGY, LABOR OR MATERIALS), WHETHER DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL AND HOWEVER ARISING, AS A RESULT OF ACCESSING OR USING THE APPLICATIONS, CONTENT, SOFTWARE TO YOUR DIGITAL DEVICE.</p>
                <p>SINCE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES, OUR PARTIES’ LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY APPICABLE LAW IN SUCH JURISDICTIONS.  IN PARTICULAR, NOTHING IN THESE TERMS SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY FOR DEATH OR PERSONAL INJURY ARISING FROM ANY OUR NEGLIGENCE OR FRAUD.  WE ALSO DO NOT ENDORSE, WARRANT OR GUARANTEE ANY THIRD PARTY PRODUCT OR SERVICE OFFERED THROUGH US AND WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>

                <h4>11. INDEMNIFICATION</h4>
                <p>11.1.  Upon our request, you agree to defend, indemnify and hold Our Parties, their licensors, contractors, vendors, content providers, and professional advisors harmless (including, without limitation, all damages, liabilities, settlements and expenses, including attorneys’ fees and costs) from any claim or demand made by any third party arising out of your use or misuse of the Applications, your violation of any term, condition, obligation, representation or warranty in the Terms for which you are responsible, in connection with your distribution of any User Content on or through the Applications, or your infringement of any intellectual property or other rights of any person or entity. Without limiting the generality of the foregoing, you agree to indemnify and hold Our Parties harmless for any improper or illegal use of your account, including the illegal or improper use of your account by someone to whom you have given permission to use. You agree that you will be personally responsible for your use of the Applications and for all of your communication and activity on or through the Applications, including any User Content you contribute, and that you will indemnify and hold harmless Our Parties from any liability or damages arising from your conduct on the Applications, including any User Content that you contribute.</p>
                <p>11.2.  We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims.  We will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.  You acknowledge and agree that the provisions in this Section shall survive any termination of your account(s), the Applications or the Terms.</p>

                <h4>12. FEES AND PAYMENT</h4>
                <p>All applicable fees, as described on the Applications, in relation to the Applications selected by you, shall be paid by you.  Upon providing notice to you (which may be through email or posting on the Applications), we reserve our rights to adjust our price list for the Applications and to charge new fees at any time.  Your use of the Applications after such notification deemed your acceptance of such new or increased charges.  Some of our Applications are free to our users, and we reserve our rights to require payment of fees for certain or all parts of such Applications.</p>

                <h4>13. THIRD PARTY SERVICES AND WEBSITES</h4>
                <p>13.1.  The Applications may contain links to third party services or websites that we do not own or control.  When you access third party services or websites, you do so at your own risk.  We have no control over, and are not responsible for, the content, accuracy, privacy policies, or practices of or opinions expressed in any such third party services or websites, and we will not and cannot monitor, verify, censor or edit the content of any third party services or websites.  You hereby represent and warrant that, in addition to your obligations under the Terms, you have read and agreed to be bound by all applicable agreements and/or policies of any third party services or websites relating to your use of the Applications and that you will act in accordance with them.</p>
                <p>13.2.  By using and accessing to the Applications, you expressly release us and hold us harmless from any and all liability arising from your use of any third party services or websites.  You are solely responsible for your dealings with organizations and/or individuals found on or through the Applications, including delivery and payments of goods or Applications, and any other terms, conditions, warranties or representations associated with such dealings.  You hereby acknowledge that we shall not be responsible or liable for any loss or damage incurred as a result of any such dealings, and we have no obligation to be involved in any disputes between you and such third party.  You further acknowledge that if you have a dispute with other users, you shall release us, our officers, directors, employees, agents, and successors in rights from damages (actual and consequential), claims and demands of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our service.</p>
                
                <h4>14. TERMINATION</h4>
                <p>The Terms shall remain effective while the Applications are being used by you.  Your use of the Applications may be terminated at any time by uninstalling them from your digital device(s).  We may terminate or suspend your use or access to any and all Applications immediately, without prior notice or liability, (i) if you breach any of the terms or conditions of the Terms; or (ii) for whatever reason or for no reason, which may result in the forfeiture and destruction of all information associated with your use of the Applications.  Any fees already paid by you hereunder are non-refundable.  Upon termination of your account, you no long have the right to use or access to the Applications.  Any and all provisions or obligations contained in the Terms which by their nature or effect are required or intended to be observed or performed after termination of the Terms will survive its expiration or termination and remain binding upon and for the benefit of the parties, their successors and permitted assigns.</p>

                <h4>15. DISPUTE RESOLUTION</h4>
                <p>15.1.  If a dispute arises between you and us, we shall provide you with a neutral and cost effective means of resolving the dispute quickly.  Therefore, both we and you agree that we will resolve any dispute, claim or controversy at law or equity that arises out of the Terms or the Applications (a <strong>“Dispute”</strong>) in accordance with this Section or as we and you otherwise agree in writing.</p>
                <p>15.2.  The Terms and all aspects of the Applications shall be governed by and construed in accordance with the laws of Singapore (excluding principles relating to the conflict of laws) regardless of your location.</p>
                <p>15.3.  You acknowledge that the rights granted and obligations made hereunder to us are of a unique and irreplaceable nature, the loss of which will cause us irreparable harm and which cannot be readily remedied in monetary damages in an action at law.  Accordingly, any breach or anticipatory breach by you shall entitle us, in addition to any claim or award for damages, injunctive relief and other equitable remedies (without the obligations of posting any bond or surety or proof of damages), costs, and reasonable attorney’s fees.  You irrevocably waive all rights to seek injunctive or other equitable relief and agree to limit your claims to claims for monetary damages (if any).</p>
                <p>15.4.  To expedite resolution and control the cost of any Dispute, we and you agree to first attempt to negotiate any Dispute informally for at least thirty (30) calendar days before initiating any arbitration proceeding. Such informal negotiations commence upon written notice from one party to the other. You will send your notice to contact@thegems.gg.</p>
                <p>15.5.  If we and you are unable to resolve a Dispute through informal negotiations, either party may elect to have the Dispute finally and exclusively resolved by binding arbitration under the Arbitration Rules of Singapore International Arbitration Centre, which Rules are deemed to be incorporated by reference into this Section. Any election to arbitrate by one party shall be final and binding on the other. The place of arbitration shall be in Singapore at the Singapore International Arbitration Centre. The number of arbitrators shall be one. The language to be used in the arbitral proceedings shall be English. We and you shall be bound by the award rendered by the arbitrator and judgment thereon may be entered in any court of competent jurisdiction. Any award rendered by the arbitrator shall be final, and neither we nor you shall have any right of appeal.</p>
                <h4>16. MISCELLANEOUS</h4>
                <p>16.1.  We make no representation that the Applications are appropriate or available in your country. The information provided through the Applications is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be prohibited by law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Applications from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
                <p>16.2.  The Terms are effective until terminated by either party.  You may terminate the Terms by destroying all Applications-related materials obtained from the Applications, us or any other website or source. The rights granted to you under the Terms will terminate immediately and automatically without notice from us if, in our sole discretion, you fail to comply with any term or provision of the Terms.</p>
                <p>16.3.  No failure on our part to exercise or to enforce any right given under the Terms or at law or any of our custom or practice at variance with the terms of the Terms will constitute a waiver of our rights under the Terms or operate so as to prevent the exercise or enforcement of any such right at any time. No right, power or remedy in the Terms conferred upon or reserved for us is exclusive of any other right, power or remedy available to us.</p>
                <p>16.4.  If any provision of the Terms is held by a court of competent jurisdiction to be invalid, void, or unenforceable, in whole or in part, the remaining provisions will nevertheless continue in full force and effect without being impaired or invalidated in any way.</p>
                <p>16.5.  We may assign or delegate the Terms and/or our Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms or Privacy Policy without our prior written consent, and any of your unauthorized assignment and delegation is void and ineffective.</p>
                <p>16.6.  We may publish additional policies related to specific services such as applications for digital device(s), forums, contests or loyalty programs from time to time. Your right to use such services is subject to those specific policies and the Terms.</p>
                <p>16.7.  Your use of the Applications includes the ability to enter into agreements and/or to make transactions electronically. You acknowledge that your electronic submissions constitute your agreement and intent to be bound by and to pay for such agreements and transactions. Your agreement and intent to be bound by electronic submissions applies to all records relating to all transactions you enter into in or through the Applications.</p>
                <p>16.8.  The Terms, any supplemental policies and any documents expressly incorporated by reference herein, contain the entire understanding between you and us, and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us with respect to the Applications, and cannot be changed or modified by you except as we posted on the relevant website.</p>
                <p>16.9.   The section headings used herein are for reference only and shall not be read to have any legal effect.</p>
                <p>16.10.  Upon our request, you will furnish us with any documentation, substantiation or releases necessary to verify your compliance with the Terms.</p>
                <p>16.11	If you have any question, please contact us at contact@thegems.gg.</p>
              </div>
            </div>
          </div>
        </section>
      );
    }
}

export default Terms;