import React from 'react';
import MyRouts from './routers/routes'
import TagManager from 'react-gtm-module'



function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-MH72SXS'
}

TagManager.initialize(tagManagerArgs)

  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default App;