import React, { Component } from 'react';


class Hero extends Component {
    
    render() {
        return (
            <section className="hero embed-container home-hero">
                <div className='D'>
                <iframe src="https://player.vimeo.com/video/706037819?h=09544e2826&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;background=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen className='vv' title="GEMS-Teaser"></iframe>
                </div>

                <div className='M vcontainer'>
                            <iframe src="https://player.vimeo.com/video/706037770?h=09544e2826&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;background=1" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen className='vv' title="GEMS-Teaser_mobile"></iframe>
                            
                </div>

                {/* <div className='hero-btn'>
                    <ul className="navbar-nav action">
                        <li className="nav-item ml-3">
                            <a href="#" className="btn ml-lg-auto btn-bordered-white" target="_blank">Learn More</a>
                        </li>
                    </ul>
                </div> */}
                
                {/* 
                    TODO: update launch 3/8
                    <div className="hero-text-overlay text-center">
                        <div className="container">
                            <h1>The strongest <br/> blockchain alliance</h1>
                            <a href="/" class="btn text-uppercase">Gems.gg</a>
                        </div>
                    </div> 
                */}
            </section>
        );
    }　
}

export default Hero;