import React, { Component } from 'react';

const initData = {
    title: "STEP INTO THE REALM OF",
    title2: "ESPORTS 3.0",
    title3: "BLOCKCHAIN GAMING",
    Gitbook: "https://official-gems.gitbook.io/gemsofficial/",
    itemImg: "/imgc/R2nd.webp",
    bannerImg: "/imgc/R3rd.gif",
}


class ItemDetails extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="item-details-area pb0 fbox pTB100">
                <div>
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                                <div className="item-thumb text-center">
                                    <img src={this.state.initData.itemImg} alt="GEMS" />
                                </div>
                            {/* <div className="item-info embed-container">
                                <div className='D'>
                                    <iframe src="https://player.vimeo.com/video/706041559?h=09544e2826&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen className='vv' title="GEMS-Teaser"></iframe>
                                </div>
                                <div className='M vcontainer'>
                                    <iframe src="https://player.vimeo.com/video/706041559?h=09544e2826&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen className='vv' title="GEMS-Teaser_mobile"></iframe>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Content */}
                            <div className="intro text-center mt70">
                                <h1 className="ptc ctm2nd"> {this.state.initData.title} <br /><span>{this.state.initData.title2}</span> <br />{this.state.initData.title3}</h1>
                                <p className="p40">GEMS is an Esports 3.0 aggregator platform within an O2O ecosystem. It propels Esports into 3.0 by integrating Gamefi, Metaverse and Socialfi elements, and leverages on its founding partners' chain of Esports hotels. </p>
                            </div>
                            <div className='mt20 text-center'>
                             <ul className="navbar-nav action">
                                <li className="nav-item ml-3">
                                    <a href={this.state.initData.Gitbook} className="btn ml-lg-auto btn-bordered-white" target="_blank">Learn More</a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row justify-content-between mt70">
                                 <div className="col-12 text-center">
                                    <img src={this.state.initData.bannerImg} className="w100" alt="GEMS" />
                                </div>
                    </div>
                </div>
                
            </section>
        );
    }
}

export default ItemDetails;