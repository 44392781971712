import React, { Component } from 'react';

const initData = {
    "preHeading": "Solving Traditional Esports Problems",
    "S1": "Low Salary",
    "S1Img" : "/imgc/about-row3S1.webp",
    "S2": "Short-lived career",
    "S2Img" : "/imgc/about-row3S2.webp",
    "S3": "Gaming Addiction",
    "S3Img" : "/imgc/about-row3S3.webp",
    "S4": "Poor Family Relationships",
    "S4Img" : "/imgc/about-row3S4.webp",
}
const S1 = [
    {
      "id": 1,
      "p": "- Underpay",
    }
]
const S2 = [
      {
        "id": 1,
        "p": "- Short Term Career",
      },
      {
        "id": 2,
        "p": "- Late Salary Payment",
      },
      {
        "id": 3,
        "p": "- Unstable",
      },
      {
        "id": 4,
        "p": "- No other skills and knowledge",
      }
]
const S3 = [
    {
      "id": 1,
      "p": "- Lost Fans",
    },
    {
      "id": 2,
      "p": "- Social Media Addiction",
    },
    {
      "id": 3,
      "p": "- Gaming Addiction",
    }
]
const S4 = [
    {
      "id": 1,
      "p": "- Not supportive",
    },
    {
      "id": 2,
      "p": "- Ruin relationships",
    },
    {
      "id": 3,
      "p": "- Health Concerns",
    }
]

class AboutRow3rd extends Component {
    state = {
      initData: {},
      S1: [],
      S2: [],
      S3: [],
      S4: []
  }
  componentDidMount(){
      this.setState({
          initData: initData,
          S1: S1,
          S2: S2,
          S3: S3,
          S4: S4
      })
  }
    render() {
        return (
            <section className="work-area pTB100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro text-center">
                                <div className="intro-content">
                                    <span>{this.state.initData.preHeading}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                       
                                <div className="col-12 col-sm-6 col-lg-3 item">
                                    {/* Single Work */}
                                    <div className="single-work pp text-center">
                                        {/* <i className={item.icon} /> */}
                                        <img src={this.state.initData.S1Img} width="60%"  />
                                        <h4>{this.state.initData.S1}</h4>
                                        
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 item">
                                    {/* Single Work */}
                                    <div className="single-work pp text-center">
                                        {/* <i className={item.icon} /> */}
                                        <img src={this.state.initData.S2Img} width="60%"  />
                                        <h4>{this.state.initData.S2}</h4>
                                        
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 item">
                                    {/* Single Work */}
                                    <div className="single-work pp text-center">
                                        {/* <i className={item.icon} /> */}
                                        <img src={this.state.initData.S3Img} width="60%"  />
                                        <h4>{this.state.initData.S3}</h4>
                                        
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 item">
                                    {/* Single Work */}
                                    <div className="single-work pp text-center">
                                        {/* <i className={item.icon} /> */}
                                        <img src={this.state.initData.S4Img} width="60%" />
                                        <h4>{this.state.initData.S4}</h4>
                                        
                                    </div>
                                </div>
                            
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutRow3rd;