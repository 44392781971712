import React, { Component } from 'react';

import Header from '../components/Header/Header';
import TeamHero from '../components/TeamHero/TeamHero';
// import Founder from '../components/Founder/Founder';
// import FoundingMember from '../components/FoundingMember/FoundingMember';
import InvestBy from '../components/InvestBy/InvestBy';
// import Adviser from '../components/Adviser/Adviser';
import Contact from '../components/Contact/Contact';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import ContactUsSection from '../components/ContactUs/contactUsSection';

class Team extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <TeamHero />
                {/* <Founder /> */}
                {/* <FoundingMember /> */}
                {/* <Adviser /> */}
                <InvestBy />
                {/* <ContactUsSection /> */}
                <GetInTouch />
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default Team;