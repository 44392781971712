import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Index from "../themes/index";
import About from "../themes/about";
import Team from "../themes/team";
import PartnersD from "../themes/partners";
import Contact from "../themes/contact";
import Investors from "../themes/investors";
import Privacy from "../themes/privacypolicy";
import TermsPage from "../themes/terms";
import ContactUs from "../themes/ContactUs";
import Faq from "../themes/faq";
import News from "../themes/news";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Index} />
            <Route exact path="/about" component={About} />
            <Route exact path="/investors" component={Investors} />
            <Route exact path="/partners" component={PartnersD} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/privacypolicy" component={Privacy} />
            <Route exact path="/terms" component={TermsPage} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/news" component={News} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;