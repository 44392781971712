import React, { Component } from 'react';

const initData = {
    title: "Documentation"
}


class Whitepaper extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="item-details-areap pTB100">
                <div className="text-center row">
                    <div className="col-12 col-lg-6 item text-center">
                            <div className="intro text-center">
                                
                                    <div className="intro-content">
                                        <span>{this.state.initData.title}</span>
                                    </div>
                               
                            </div>
                    </div>
                    <div className="col-6 col-lg-3 item text-center">
                    <a href="https://drive.google.com/file/d/1X1V24P6ssEPeRXjCUhLOxpuU59GnJUOz/view?usp=drivesdk" target="_blank"  rel="noopener noreferrer">
                    <img src="/imgc/pitchdeck.webp" />
                    <h2 className='tWhite'>Pitch Deck</h2>
                    </a>
                    </div>
                    <div className="col-6 col-lg-3 item text-center">
                        <a href="https://official-gems.gitbook.io/gemsofficial/" target="_blank"  rel="noopener noreferrer">
                            <img src="/imgc/gitbook.webp" />
                            <h2 className='tWhite'>GitBook</h2>
                        </a>
                    </div>
                </div>          
                
            </section>
        );
    }
}

export default Whitepaper;