import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@thegemsgg";

function st(v){
    if ((v===null) || (v==='')){
         return false;
     }else{
        let pos = v.indexOf("</figure>")+9; 
        v = v.substring(pos);
        v = v.toString();    
        v = v.replace( /(<([^>]+)>)/ig, '');
        v = v.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '');
        return v.substring(0, 100) + "...";
 }
 } 

class News extends Component {
    state = {
        data: {},
        articles: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    articles: res.data.items
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }

 
    render() {
        return (
            <section className="work-area pTB100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro text-center">
                                <div className="intro-content">
                                    <span>NEWS & ANNOUNCEMENT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-container slider-news items">
                        <div className="swiper-wrapper">
                            {this.state.articles.slice(0, 4).map((item, idx) => {
                                return (
                                    <div key={`wd_${idx}`} className="swiper-slide item">
                                        {/* Single Work */}
                                        <div className="single-work">
                                            <a href={item.link}>
                                            {/* <i className={item.icon} /> */}
                                            <img src={item.thumbnail} />
                                            <h4 dangerouslySetInnerHTML={{ __html: item.title }} />
                                            <p>{st(item.description)}</p>
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </div>
                </div>
            </section>
        );
    }
}

export default News;